import { Alert, Backdrop, Box, Button, Card, CardContent, CircularProgress, Link, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import ToppMeny from '../komponenter/Toppmeny'
import { Text } from '../komponenter/Tekst'
import AccountCircle from '@mui/icons-material/AccountCircle';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import SettingsIcon from '@mui/icons-material/Settings';
import BusinessIcon from '@mui/icons-material/Business';
import ListAltIcon from '@mui/icons-material/ListAlt';
import Tjenesteknapper from '../komponenter/Tjenesteknapper';
import VerifiedIcon from '@mui/icons-material/Verified';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Grid from '@mui/material/Grid2';

function Forsiden() {
    const [isLoading, setisLoading] = useState(true);
    const jvbruker = sessionStorage.getItem('jvbruker');
    const jvnavn = sessionStorage.getItem('jvnavn');
    const jvmobilnummer = sessionStorage.getItem('jvmobilnummer');
    const jvaktornavn = sessionStorage.getItem('jvaktornavn');
    const jvaktororgnr = sessionStorage.getItem('jvaktororgnummer');
    const token = sessionStorage.getItem("jvtoken") || "";
    const jvsesaktorid = sessionStorage.getItem('jvaktorid') || "";

    const [jvbrukerverifisert, setjvbrukerverifisert] = useState(false);
    const [jvmobilnummerverifisert, setjvmobilnummerverifisert] = useState(false);
    const [jvaktorverifisert, setjvaktorverifisert] = useState(false);

    useEffect(() => {
        document.title = 'Din Side - Justervesenet';
        if (token !== "") {
            setisLoading(false);
        }
        else {
            window.location.assign("/logginn");
        }
        if ((jvsesaktorid === "undefined") || (jvsesaktorid === null)) {
            window.location.assign("/profil");
        }
        const jvbrukerverifisert = sessionStorage.getItem('jvbrukerverifisert');
        const isVerified = jvbrukerverifisert === 'true';
        setjvbrukerverifisert(isVerified);
        const jvmobilnummerverifisert = sessionStorage.getItem('jvmobilnummerverifisert');
        const isVerifiedMobil = jvmobilnummerverifisert === 'true';
        setjvmobilnummerverifisert(isVerifiedMobil);
        const jvaktorverifisert = sessionStorage.getItem('jvaktorverifisert');
        const isVerifiedAktor = jvaktorverifisert === 'true';
        setjvaktorverifisert(isVerifiedAktor);
    }, [token, jvsesaktorid]);

    return (
        <main>
            {
                (isLoading) ?
                    <div>
                        <Backdrop
                            open={isLoading}
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop >
                    </div>
                    :
                    (token && (jvsesaktorid !== "undefined") && (jvsesaktorid !== null)) &&
                    <div>
                        <ToppMeny />
                        <Grid container spacing={2} p={2}>
                            <Grid size={{ xs: 12, md: 6, lg: 8 }}>
                                <Alert sx={{ mb: 2 }} severity="warning">
                                    <b>Viktig informasjon</b>
                                    <p>
                                        Alle brukerkontoer på denne siden skal være personlige slik at vi vet hvem som sender inn meldinger på vegne av en virksomhet. Hvis navnet på din brukerkonto som per nå er <b>{jvnavn}</b> ikke er ditt personlige navn så må dette endres.
                                    </p>
                                </Alert>
                                {(!jvbrukerverifisert) &&
                                    <Alert sx={{ mt: 2 }} severity="error"><Text tid="epostikkeverifisert" /> <Text tid="gaatil" /> <Link href='/profil'><Text tid="minprofil" /></Link> <Text tid="gjennomforeverifisering" /></Alert>
                                }
                                {(!jvmobilnummerverifisert) &&
                                    <Alert sx={{ mt: 2 }} severity="error"><Text tid="mobilikkeverifisert" /> <Text tid="gaatil" /> <Link href='/profil'><Text tid="minprofil" /></Link> <Text tid="gjennomforeverifisering" /></Alert>
                                }
                                {(!jvaktorverifisert) &&
                                    <Alert sx={{ mt: 2 }} severity="error"><Text tid="dintilknytningaktor" /> <b>{jvaktornavn}</b> <Text tid="erikkeverifisert" /> <Text tid="gaatil" /> <Link href='/profil'><Text tid="minprofil" /></Link> <Text tid="formerinformasjon" /></Alert>
                                }
                                <Typography variant="h5" color="text.secondary" component="h2" sx={{ mt: 2, mb: 3 }}>
                                    <Text tid="tjenester" />
                                </Typography>
                                <Box display="flex" width="100%" maxWidth="900px">
                                    <Box m={2}>
                                        <Tjenesteknapper link="/taksameter" Icon="LocalTaxiIcon" Tittel="taksameter" Info="servicetaksameterinfo" />
                                    </Box>
                                    <Box m={2}>
                                        <Tjenesteknapper link="/innsending" Icon="AssignmentIcon" Tittel="innsending" Info="innsendinginfo" />
                                    </Box>
                                    <Box m={2}>
                                        <Tjenesteknapper link="/aarsavgift" Icon="PointOfSaleIcon" Tittel="aarsavgift" Info="aarsavgiftinfo" />
                                    </Box>
                                    <Box m={2}>
                                        <Tjenesteknapper link="/arkiv" Icon="FolderIcon" Tittel="arkiv" Info="arkivinfo" />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid size={{ xs: 12, md: 6, lg: 4 }}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Grid container sx={{ alignItems: 'flex-start' }}>
                                            {/* Første kolonne: jvnavn, jvbruker, jvmobilnummer */}
                                            <Grid size={{ xs: 12 }}>
                                                <Typography variant="h6" color="text.secondary" className='jvtypeicon' gutterBottom>
                                                    <AccountCircle className='jvtexticon' /> {jvnavn}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary" className='jvtypeicon' sx={{ ml: 3 }}>
                                                    <EmailIcon className='jvtexticonnc' /> {jvbruker} {(!jvbrukerverifisert) ? <WarningAmberIcon className='color-yellow' sx={{ ml: 1 }} /> : <VerifiedIcon className='jviconverified' sx={{ ml: 1 }} />}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary" className='jvtypeicon' sx={{ ml: 3, mb: 3 }}>
                                                    <PhoneIcon className='jvtexticonnc' /> {jvmobilnummer} {(!jvmobilnummerverifisert) ? <WarningAmberIcon className='color-yellow' sx={{ ml: 1 }} /> : <VerifiedIcon className='jviconverified' sx={{ ml: 1 }} />}
                                                </Typography>
                                                <Typography variant="h6" color="text.secondary" className='jvtypeicon' gutterBottom>
                                                    <BusinessIcon className='jvtexticon' /> {jvaktornavn} {(!jvaktorverifisert) ? <WarningAmberIcon className='color-yellow' sx={{ ml: 1 }} /> : <VerifiedIcon className='jviconverified' sx={{ ml: 1 }} />}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary" className='jvtypeicon' sx={{ ml: 3, mb: 3 }}>
                                                    <ListAltIcon className='jvtexticonnc' /> {jvaktororgnr}
                                                </Typography>

                                                <Button
                                                    size="small"
                                                    href="/profil"
                                                    variant="outlined"
                                                    startIcon={<SettingsIcon />}
                                                    sx={{ mr: 1 }}
                                                >
                                                    <Text tid="minprofil" />
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>

                        </Grid>
                    </div>
            }
        </main>
    );
}

export default Forsiden;