import { Alert, Backdrop, Box, Breadcrumbs, Button, Card, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Input, InputLabel, Link, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ToppMeny from '../komponenter/Toppmeny'
import { Text } from '../komponenter/Tekst'
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from '@mui/icons-material/Home';
import SaveIcon from '@mui/icons-material/Save';
import SearchIcon from '@mui/icons-material/Search';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import Grid from '@mui/material/Grid2';
import * as yup from 'yup';
import { ErrorMessage, Field, Form, Formik, FormikHelpers, useFormik } from 'formik';

const APIURL = process.env.REACT_APP_APIURL;

function Taksameter() {
    const token = sessionStorage.getItem("jvtoken") || "";
    const [isLoading, setisLoading] = useState(true);
    const [aktorer, setAktorer] = useState<any[]>([]);
    const [nyaktor, setNyAktor] = useState<any[]>([]);
    const [ModalNyAktor, setModalNyAktor] = useState(false);
    const [NyAktorOrgNr, setNyAktorOrgNr] = useState(0);
    const [NyAktorLoyver, setNyAktorLoyver] = useState(0);
    const [ModalOSS, setModalOSS] = useState(false);

    const jvaktornavn = sessionStorage.getItem('jvaktornavn');
    const jvaktortaxiservicefirma = sessionStorage.getItem('jvaktortaxiservicefirma');
    const [jvbrukerverifisert, setjvbrukerverifisert] = useState(sessionStorage.getItem('jvbrukerverifisert'));
    const [jvmobilnummerverifisert, setjvmobilnummerverifisert] = useState(sessionStorage.getItem('jvmobilnummerverifisert'));
    const [jvaktorverifisert, setjvaktorverifisert] = useState(sessionStorage.getItem('jvaktorverifisert'));


    interface FormInterfaceOrgNummerSok {
        OrgNummer: string;
    }
    const FormValuesOrgNummerSok: FormInterfaceOrgNummerSok = {
        OrgNummer: "",
    };

    const FormValidationOrgNummerSok = yup.object({
        OrgNummer: yup.string()
            .matches(/^[0-9]{9}$/, 'Organisasjonsnummer må være 9 siffer')
            .required('Organisasjonsnummer er påkrevd'),
    });

    const handleSubmitOrgNummerSok = (
        values: FormInterfaceOrgNummerSok,
    ) => {
        setisLoading(true);
        var formdata = {
            Orgnummer: values.OrgNummer
        };
        axios.post(APIURL + "/taxi/orgnrsok", formdata, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setAktorer(response.data);
                }
                else {
                }
                setisLoading(false);
            })
            .catch((e: any) => {
                if ((e.response.status === 401) || (e.response.status === 403)) {
                    sessionStorage.clear();
                    window.location.assign("/");
                }
                setisLoading(false);
            });
    }

    const ModalOSSAapne = () => {
        setModalOSS(true);
    }

    const ModalOSSLukk = () => {
        setModalOSS(false);
    }

    const handleSubmitSaksNummerSok = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setisLoading(true);
        var data = new FormData(event.currentTarget);
        var formdata = {
            Saksnummer: data.get('Saksnummer'),
        };
        axios.post(APIURL + "/taxi/saksnrsok", formdata, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setAktorer(response.data);
                }
                else {
                }
                setisLoading(false);
            })
            .catch((e: any) => {
                if ((e.response.status === 401) || (e.response.status === 403)) {
                    sessionStorage.clear();
                    window.location.assign("/");
                }
                setisLoading(false);
            });
    }

    const handleSubmitLoyveNummerSok = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setisLoading(true);
        var data = new FormData(event.currentTarget);
        var formdata = {
            Loyvenummer: data.get('Loyvenummer'),
        };
        axios.post(APIURL + "/taxi/loyvenrsok", formdata, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setAktorer(response.data);
                }
                else {
                }
                setisLoading(false);
            })
            .catch((e: any) => {
                if ((e.response.status === 401) || (e.response.status === 403)) {
                    sessionStorage.clear();
                    window.location.assign("/");
                }
                setisLoading(false);
            });
    }

    const TaksameterAktor = (orgnr: number) => {
    }

    const ModalNyAktorLukk = () => {
        setModalNyAktor(false);
    };

    const ModalNyAktorAapne = async (orgnr: number, loyver: number) => {
        setNyAktorOrgNr(orgnr);
        await axios.get(APIURL + "/brreg/" + orgnr, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setNyAktorLoyver(loyver);
                    setNyAktor(response.data);
                }
                else {
                }
            })
            .catch((e: any) => {
                if ((e.response.status === 401) || (e.response.status === 403)) {
                    sessionStorage.clear();
                    window.location.assign("/");
                }
            });
        setModalNyAktor(true);
    };

    const SendNyAktor = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setisLoading(true);
        ModalNyAktorLukk();
        var data = new FormData(event.currentTarget);
        var formdata = {
            orgnr: NyAktorOrgNr,
            kontaktpersonaktorid: sessionStorage.getItem('jvaktorid'),
            navn: data.get('navn'),
            besoksadresse: data.get('besoksadresse'),
            besokspostnr: data.get('besokspostnr'),
            besokssted: data.get('besokssted'),
            postadresse: data.get('postadresse'),
            postpostnr: data.get('postpostnr'),
            poststed: data.get('poststed'),
            telefon: data.get('telefon'),
            mobilnummer: data.get('mobilnummer'),
            epost: data.get('epost')
        };
        await axios.post(APIURL + "/taxi/nyaktor", formdata, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    window.location.assign("/taksameter/" + NyAktorOrgNr);
                }
                else {
                }
            })
            .catch((e: any) => {
                if ((e.response.status === 401) || (e.response.status === 403)) {
                    sessionStorage.clear();
                    window.location.assign("/");
                }
            });
        setisLoading(false);
    }

    const handleSubmitAktorNavnSok = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setisLoading(true);
        var data = new FormData(event.currentTarget);
        var formdata = {
            AktorNavn: data.get('AktorNavn'),
        };
        axios.post(APIURL + "/taxi/aktornavnsok", formdata, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setAktorer(response.data);
                }
                else {
                }
                setisLoading(false);
            })
            .catch((e: any) => {
                if ((e.response.status === 401) || (e.response.status === 403)) {
                    sessionStorage.clear();
                    window.location.assign("/");
                }
                setisLoading(false);
            });
    }

    useEffect(() => {
        document.title = 'Taksameter - Justervesenet';
        setisLoading(false);
    }, []);

    return (
        <main>
            {
                isLoading ?
                    <div>
                        <Backdrop
                            open={isLoading}
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop >
                    </div>
                    :
                    (jvbrukerverifisert === "true" && jvmobilnummerverifisert === "true" && jvaktorverifisert === "true" && jvaktortaxiservicefirma === "true") ?
                        <div>
                            <ToppMeny />
                            <Breadcrumbs aria-label="breadcrumb" sx={{ padding: 1 }}>
                                <Link
                                    underline="hover"
                                    sx={{ display: 'flex', alignItems: 'center' }}
                                    color="inherit"
                                    href="/forsiden"
                                >
                                    <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                    <Text tid="forsiden" />
                                </Link>
                                <Typography
                                    sx={{ display: 'flex', alignItems: 'center' }}
                                    color="text.primary"
                                >
                                    <Text tid="taksameter" />
                                </Typography>
                            </Breadcrumbs>

                            <Dialog
                                fullWidth
                                maxWidth="lg"
                                open={ModalOSS}
                                onClose={ModalOSSLukk}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">
                                    <Text tid="oss" />
                                </DialogTitle>
                                <DialogContent>
                                    <Typography variant="subtitle2">
                                        <Text tid="oss1tittel" />
                                    </Typography>
                                    <Typography variant="body2" sx={{ mb: 2 }}>
                                        <Text tid="oss1tekst" />
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        <Text tid="oss2tittel" />
                                    </Typography>
                                    <Typography variant="body2" sx={{ mb: 2 }}>
                                        <Text tid="oss2tekst" />
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        <Text tid="oss3tittel" />
                                    </Typography>
                                    <Typography variant="body2" sx={{ mb: 2 }}>
                                        <Text tid="oss3tekst" />
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        <Text tid="oss4tittel" />
                                    </Typography>
                                    <Typography variant="body2" sx={{ mb: 2 }}>
                                        <Text tid="oss4tekst" />
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        <Text tid="oss5tittel" />
                                    </Typography>
                                    <Typography variant="body2" sx={{ mb: 2 }}>
                                        <Text tid="oss5tekst" />
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        <Text tid="oss6tittel" />
                                    </Typography>
                                    <Typography variant="body2" sx={{ mb: 2 }}>
                                        <Text tid="oss6tekst" />
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        <Text tid="oss7tittel" />
                                    </Typography>
                                    <Typography variant="body2" sx={{ mb: 2 }}>
                                        <Text tid="oss7tekst" />
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        <Text tid="oss8tittel" />
                                    </Typography>
                                    <Typography variant="body2" sx={{ mb: 2 }}>
                                        <Text tid="oss8tekst" />
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        <Text tid="oss9tittel" />
                                    </Typography>
                                    <Typography variant="body2" sx={{ mb: 2 }}>
                                        <Text tid="oss9tekst" />
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        <Text tid="oss10tittel" />
                                    </Typography>
                                    <Typography variant="body2" sx={{ mb: 2 }}>
                                        <Text tid="oss10tekst" />
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        <Text tid="oss11tittel" />
                                    </Typography>
                                    <Typography variant="body2" sx={{ mb: 2 }}>
                                        <Text tid="oss11tekst" />
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        <Text tid="oss12tittel" />
                                    </Typography>
                                    <Typography variant="body2" sx={{ mb: 2 }}>
                                        <Text tid="oss12tekst" />
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        <Text tid="oss13tittel" />
                                    </Typography>
                                    <Typography variant="body2" sx={{ mb: 2 }}>
                                        <Text tid="oss13tekst" />
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        <Text tid="oss14tittel" />
                                    </Typography>
                                    <Typography variant="body2" sx={{ mb: 2 }}>
                                        <Text tid="oss14tekst" />
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        <Text tid="oss15tittel" />
                                    </Typography>
                                    <Typography variant="body2" sx={{ mb: 2 }}>
                                        <Text tid="oss15tekst" />
                                    </Typography>
                                </DialogContent>
                            </Dialog>

                            <Dialog
                                fullWidth
                                maxWidth="lg"
                                open={ModalNyAktor}
                                onClose={ModalNyAktorLukk}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">
                                    <Text tid="opprettnyaktor" />
                                </DialogTitle>
                                {(NyAktorLoyver !== 0) ?
                                    <Box component="form" onSubmit={SendNyAktor}>
                                        <DialogContent>
                                            <Grid size={{ xs: 12 }} sx={{ mb: 1 }}>
                                                <TextField
                                                    margin="normal"
                                                    fullWidth
                                                    id="orgnr"
                                                    label={Text({ tid: "organisasjonsnummer" })}
                                                    name="orgnr"
                                                    defaultValue={NyAktorOrgNr}
                                                    required
                                                />
                                            </Grid>
                                            <Grid size={{ xs: 12 }} sx={{ mb: 1 }}>
                                                <TextField
                                                    margin="normal"
                                                    fullWidth
                                                    id="navn"
                                                    label={Text({ tid: "aktornavn" })}
                                                    name="navn"
                                                    defaultValue={nyaktor[0]?.navn}
                                                    required
                                                />
                                            </Grid>
                                            <Grid container>
                                                <Grid size={{ xs: 6 }} sx={{ mb: 1, pr: 1 }}>
                                                    <TextField
                                                        margin="normal"
                                                        fullWidth
                                                        id="besoksadresse"
                                                        label={Text({ tid: "besoksadresse" })}
                                                        name="besoksadresse"
                                                        defaultValue={nyaktor[0]?.forretningsadr}
                                                        required
                                                    />
                                                </Grid>
                                                <Grid size={{ xs: 2 }} sx={{ mb: 1, pr: 1 }}>
                                                    <TextField
                                                        margin="normal"
                                                        fullWidth
                                                        id="besokspostnr"
                                                        label={Text({ tid: "postnummer" })}
                                                        name="besokspostnr"
                                                        defaultValue={nyaktor[0]?.forradrpostnr}
                                                        required
                                                    />
                                                </Grid>
                                                <Grid size={{ xs: 4 }} sx={{ mb: 1 }}>
                                                    <TextField
                                                        margin="normal"
                                                        fullWidth
                                                        id="besokssted"
                                                        label={Text({ tid: "sted" })}
                                                        name="besokssted"
                                                        defaultValue={nyaktor[0]?.forradrpoststed}
                                                        required
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container>
                                                <Grid size={{ xs: 4 }} sx={{ mb: 1, pr: 1 }}>
                                                    <TextField
                                                        margin="normal"
                                                        fullWidth
                                                        id="mobilnummer"
                                                        label={Text({ tid: "mobilnummer" })}
                                                        name="mobilnummer"
                                                        required
                                                    />
                                                </Grid>
                                                <Grid size={{ xs: 4 }} sx={{ mb: 1, pr: 1 }}>
                                                    <TextField
                                                        margin="normal"
                                                        fullWidth
                                                        id="epost"
                                                        name="epost"
                                                        label={Text({ tid: "epost" })}
                                                        required
                                                    />
                                                </Grid>
                                                <Grid size={{ xs: 4 }} sx={{ mb: 1, pr: 1 }}>
                                                    <TextField
                                                        margin="normal"
                                                        fullWidth
                                                        id="telefon"
                                                        label={Text({ tid: "telefonnummer" })}
                                                        name="telefon"
                                                    />
                                                </Grid>
                                            </Grid>

                                        </DialogContent>
                                        <DialogActions>
                                            <Button
                                                size="small"
                                                type="submit"
                                                variant="contained"
                                                color="success"
                                            >
                                                <SaveIcon className='jvbtnicon' />
                                                <Text tid="opprettaktor" />
                                            </Button>
                                            <Button
                                                size="small"
                                                variant="contained"
                                                color="error"
                                                onClick={ModalNyAktorLukk}
                                            >
                                                <CloseIcon className='jvbtnicon' />
                                                <Text tid="avbryt" />
                                            </Button>
                                        </DialogActions>
                                    </Box>
                                    :
                                    <Box>
                                        <DialogContent>
                                            <Alert severity="error">
                                                <Text tid="nyaktoringenloyver" />
                                            </Alert>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button
                                                size="small"
                                                variant="contained"
                                                color="error"
                                                onClick={ModalNyAktorLukk}
                                            >
                                                <CloseIcon className='jvbtnicon' />
                                                <Text tid="lukk" />
                                            </Button>
                                        </DialogActions>
                                    </Box>
                                }
                            </Dialog>
                            <Grid container spacing={2} p={2}>
                                <Grid size={{ xs: 12 }}>
                                    <Alert variant="outlined" severity="info" sx={{ mb: 2 }}>
                                        <Button
                                            size="small"
                                            type="submit"
                                            variant="contained"
                                            color="info"
                                            onClick={ModalOSSAapne}
                                        >
                                            <Text tid="oss" />
                                        </Button>
                                        &nbsp;
                                        <Button
                                            size="small"
                                            type="submit"
                                            variant="contained"
                                            color="info"
                                            href="https://kvalitet.justervesenet.no/docs/pub/DOK01112.pdf"
                                        >
                                            <Text tid="brukerveiledning" />
                                        </Button>
                                    </Alert>
                                    <Typography variant="h6" component="h2" className='jvcardtitle'>
                                        <SearchIcon className='jvcardtitleicon' /> <Text tid="sokloyvehaver" />
                                    </Typography>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <Grid container>
                                                <Grid size={{ xs: 12, sm: 6 }} sx={{ pr: 2 }}>
                                                    <Box component="form" onSubmit={handleSubmitLoyveNummerSok} sx={{ mb: 5 }}>
                                                        <FormControl fullWidth sx={{ mb: 2 }} variant="standard" >
                                                            <InputLabel htmlFor="Loyvenummer"><Text tid="sokloyvenummer" /></InputLabel>
                                                            <Input
                                                                id="Loyvenummer"
                                                                name="Loyvenummer"
                                                                fullWidth
                                                                required
                                                            />
                                                        </FormControl>
                                                        <Button
                                                            size="small"
                                                            type="submit"
                                                            variant="outlined"
                                                            color="success"
                                                        >
                                                            <SearchIcon className='jvbtnicon' />
                                                            <Text tid="sok" />
                                                        </Button>
                                                    </Box>
                                                </Grid>
                                                <Grid size={{ xs: 12, sm: 6 }} sx={{ pr: 2 }}>
                                                    <Box component="form" onSubmit={handleSubmitSaksNummerSok} sx={{ mb: 5 }}>
                                                        <FormControl fullWidth sx={{ mb: 2 }} variant="standard" >
                                                            <InputLabel htmlFor="navn"><Text tid="soksaksnummer" /></InputLabel>
                                                            <Input
                                                                id="Saksnummer"
                                                                name="Saksnummer"
                                                                fullWidth
                                                                required
                                                            />
                                                        </FormControl>
                                                        <Button
                                                            size="small"
                                                            type="submit"
                                                            variant="outlined"
                                                            color="success"
                                                        >
                                                            <SearchIcon className='jvbtnicon' />
                                                            <Text tid="sok" />
                                                        </Button>
                                                    </Box>
                                                </Grid>
                                                <Grid size={{ xs: 12, sm: 6 }} sx={{ pr: 2 }}>
                                                    <Formik
                                                        initialValues={FormValuesOrgNummerSok}
                                                        validationSchema={FormValidationOrgNummerSok}
                                                        onSubmit={handleSubmitOrgNummerSok}
                                                    >
                                                        {({ errors, touched, setFieldValue }) => (
                                                            <Form>
                                                                <Box component="div" sx={{ mb: 5 }}>
                                                                    <FormControl fullWidth sx={{ mb: 2 }} variant="standard">
                                                                        <InputLabel htmlFor="OrgNummer">
                                                                            <Text tid="sokorgnummer" />
                                                                        </InputLabel>
                                                                        <Field
                                                                            as={Input}
                                                                            id="OrgNummer"
                                                                            name="OrgNummer"
                                                                            fullWidth
                                                                            required
                                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                                const numericValue = e.target.value.replace(/[^0-9]/g, '');
                                                                                setFieldValue("OrgNummer", numericValue);
                                                                            }}
                                                                            inputProps={{
                                                                                maxLength: 9,
                                                                                pattern: "[0-9]*"
                                                                            }}
                                                                            error={touched.OrgNummer && !!errors?.OrgNummer}
                                                                        />
                                                                        <ErrorMessage name="OrgNummer" component="div" className="input-error-message" />
                                                                    </FormControl>
                                                                    <Button
                                                                        size="small"
                                                                        type="submit"
                                                                        variant="outlined"
                                                                        color="success"
                                                                    >
                                                                        <SearchIcon className='jvbtnicon' />
                                                                        <Text tid="sok" />
                                                                    </Button>
                                                                </Box>
                                                            </Form>
                                                        )}
                                                    </Formik>
                                                </Grid>
                                                <Grid size={{ xs: 12, sm: 6 }} sx={{ pr: 2 }}>
                                                    <Box component="form" onSubmit={handleSubmitAktorNavnSok} sx={{ mb: 5 }}>
                                                        <FormControl fullWidth sx={{ mb: 2 }} variant="standard" >
                                                            <InputLabel htmlFor="navn"><Text tid="sokaktornavn" /></InputLabel>
                                                            <Input
                                                                id="AktorNavn"
                                                                name="AktorNavn"
                                                                fullWidth
                                                                required
                                                            />
                                                        </FormControl>
                                                        <Button
                                                            size="small"
                                                            type="submit"
                                                            variant="outlined"
                                                            color="success"
                                                        >
                                                            <SearchIcon className='jvbtnicon' />
                                                            <Text tid="sok" />
                                                        </Button>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid size={{ xs: 12 }}>
                                    <Typography variant="h6" component="h2" className='jvcardtitle'>
                                        <SearchIcon className='jvcardtitleicon' /> <Text tid="soketreff" /> {(aktorer[0]?.resultater) && <span> &nbsp; ({aktorer[0]?.resultater} <Text tid="treff" />)</span>}
                                    </Typography>
                                    {((aktorer[0]?.resultater) && (aktorer[0]?.resultater > 10)) && <Typography variant="body2"><i><Text tid="flereenntifunn" /></i></Typography>}
                                    <p></p>
                                    {aktorer && aktorer.map(({ orgnr, navn, forretningsadr, forradrpostnr, forradrpoststed, forradrkommnavn, AktorId, Aktiv, loyver, taksametere }: any, index: number) => {
                                        return <span key={orgnr}>
                                            {(AktorId) ?
                                                <Link href={(Aktiv) ? "/taksameter/" + orgnr : "#"} onClick={(!Aktiv) ? e => ModalNyAktorAapne(orgnr, loyver) : e => TaksameterAktor(orgnr)} underline="none">
                                                    <Card variant="outlined" className={(Aktiv) ? "CardLinkA" : "CardLinkNA"} sx={{ mb: 2 }}>
                                                        <CardContent>
                                                            <Typography variant="h6" color="text.secondary" gutterBottom>
                                                                {navn}
                                                            </Typography>
                                                            <Typography variant="body2" color="text.secondary" className='jvtypeicon' gutterBottom>
                                                                {orgnr}
                                                            </Typography>
                                                            <Typography variant="body2" color="text.secondary" className='jvtypeicon' gutterBottom>
                                                                <HomeIcon className='jvtexticon' /> {forretningsadr}, {forradrpostnr} ({forradrpoststed}) [{forradrkommnavn}]
                                                            </Typography>
                                                            <p></p>
                                                            {(AktorId) ?
                                                                (Aktiv) ?
                                                                    <Typography variant="body2" color="text.secondary" className='jvtypeicon'>
                                                                        <CheckBoxIcon className='jvtexticon' color="success" /> <Text tid="reghosjv" />
                                                                    </Typography>
                                                                    :
                                                                    <Typography variant="body2" color="text.secondary" className='jvtypeicon'>
                                                                        <CheckBoxIcon className='jvtexticon' color="error" /> <Text tid="reghosjvinaktiv" />
                                                                    </Typography>
                                                                :
                                                                <Typography variant="body2" color="text.secondary" className='jvtypeicon'>
                                                                    <DisabledByDefaultIcon className='jvtexticon' color="error" /> <Text tid="ikkereghosjv" />
                                                                </Typography>
                                                            }
                                                            {(loyver !== 0) ?
                                                                <Typography variant="body2" color="text.secondary" className='jvtypeicon'>
                                                                    <CheckBoxIcon className='jvtexticon' color="success" /> {loyver} <Text tid="aktiveloyveriloyvereg" />
                                                                </Typography>
                                                                :
                                                                <Typography variant="body2" color="text.secondary" className='jvtypeicon'>
                                                                    <DisabledByDefaultIcon className='jvtexticon' color="error" /> <Text tid="ingenaktiveloyvereg" />
                                                                </Typography>
                                                            }
                                                            {(taksametere !== 0) ?
                                                                <Typography variant="body2" color="text.secondary" className='jvtypeicon'>
                                                                    <CheckBoxIcon className='jvtexticon' color="success" /> {taksametere} <Text tid="aktivetaksameteresok" />
                                                                </Typography>
                                                                :
                                                                <Typography variant="body2" color="text.secondary" className='jvtypeicon'>
                                                                    <DisabledByDefaultIcon className='jvtexticon' color="error" /> <Text tid="ingenaktivetaksametere" />
                                                                </Typography>
                                                            }
                                                            <p></p>
                                                        </CardContent>
                                                    </Card>
                                                </Link>
                                                :
                                                <Link onClick={e => ModalNyAktorAapne(orgnr, loyver)} underline="none">
                                                    <Card variant="outlined" className="CardLinkNA" sx={{ mb: 2 }}>
                                                        <CardContent>
                                                            <Typography variant="h6" color="text.secondary" gutterBottom>
                                                                {navn}
                                                            </Typography>
                                                            <Typography variant="body2" color="text.secondary" className='jvtypeicon' gutterBottom>
                                                                {orgnr}
                                                            </Typography>
                                                            <Typography variant="body2" color="text.secondary" className='jvtypeicon' gutterBottom>
                                                                <HomeIcon className='jvtexticon' /> {forretningsadr}, {forradrpostnr} ({forradrpoststed}) [{forradrkommnavn}]
                                                            </Typography>
                                                            <p></p>
                                                            {AktorId ?
                                                                <Typography variant="body2" color="text.secondary" className='jvtypeicon'>
                                                                    <CheckBoxIcon className='jvtexticon' color="success" /> <Text tid="reghosjv" />
                                                                </Typography>
                                                                :
                                                                <Typography variant="body2" color="text.secondary" className='jvtypeicon'>
                                                                    <DisabledByDefaultIcon className='jvtexticon' color="error" /> <Text tid="ikkereghosjv" />
                                                                </Typography>
                                                            }
                                                            {(loyver !== 0) ?
                                                                <Typography variant="body2" color="text.secondary" className='jvtypeicon'>
                                                                    <CheckBoxIcon className='jvtexticon' color="success" /> {loyver} <Text tid="aktiveloyveriloyvereg" />
                                                                </Typography>
                                                                :
                                                                <Typography variant="body2" color="text.secondary" className='jvtypeicon'>
                                                                    <DisabledByDefaultIcon className='jvtexticon' color="error" /> <Text tid="ingenaktiveloyvereg" />
                                                                </Typography>
                                                            }
                                                            {(taksametere !== 0) ?
                                                                <Typography variant="body2" color="text.secondary" className='jvtypeicon'>
                                                                    <CheckBoxIcon className='jvtexticon' color="success" /> {taksametere} <Text tid="aktivetaksameteresok" />
                                                                </Typography>
                                                                :
                                                                <Typography variant="body2" color="text.secondary" className='jvtypeicon'>
                                                                    <DisabledByDefaultIcon className='jvtexticon' color="error" /> <Text tid="ingenaktivetaksametere" />
                                                                </Typography>
                                                            }
                                                            <p></p>
                                                        </CardContent>
                                                    </Card>
                                                </Link>

                                            }


                                        </span>
                                    })
                                    }
                                </Grid>
                            </Grid>
                        </div>
                        :
                        <div>
                            <ToppMeny />
                            <Breadcrumbs aria-label="breadcrumb" sx={{ padding: 1 }}>
                                <Link
                                    underline="hover"
                                    sx={{ display: 'flex', alignItems: 'center' }}
                                    color="inherit"
                                    href="/forsiden"
                                >
                                    <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                    <Text tid="forsiden" />
                                </Link>
                                <Typography
                                    sx={{ display: 'flex', alignItems: 'center' }}
                                    color="text.primary"
                                >
                                    <Text tid="taksameter" />
                                </Typography>
                            </Breadcrumbs>
                            <Grid container spacing={2} p={2}>
                                {((jvbrukerverifisert !== "true") || (jvmobilnummerverifisert !== "true")) &&
                                    <Typography variant="h6" component="h2">
                                        <Alert severity="error">
                                            Du har ikke tilgang til denne siden før du har verifisert din epost og mobilnummer. Gå til <Link href="/profil">min profil</Link> for å gjøre dette.
                                        </Alert>
                                    </Typography>
                                }
                                {(jvaktorverifisert !== "true") &&
                                    <Typography variant="h6" component="h2">
                                        <Alert severity="error">
                                            Din bruker er ikke verifisert mot aktøren du er tilknyttet til  (<b>{jvaktornavn}</b>). Gå til <Link href="/profil">min profil</Link> for å gjøre dette.
                                        </Alert>
                                    </Typography>
                                }
                                {(jvaktortaxiservicefirma !== "true") &&
                                    <Typography variant="h6" component="h2">
                                        <Alert severity="error">
                                            Aktøren du er tilknyttet (<b>{jvaktornavn}</b>) ikke registrert som et servicefirma hos Justervesenet. Kontakt Justervesenet dersom dette er et servicefirma for taxi.
                                        </Alert>
                                    </Typography>
                                }
                            </Grid>
                        </div>
            }
        </main >
    )
}

export default Taksameter;