import { Alert, Backdrop, Breadcrumbs, Button, Card, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Link, Snackbar, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ToppMeny from '../komponenter/Toppmeny'
import { Text } from '../komponenter/Tekst'
import axios from 'axios';
import HomeIcon from '@mui/icons-material/Home';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import Grid from '@mui/material/Grid2';
import { useParams } from 'react-router-dom';
import BusinessIcon from '@mui/icons-material/Business';

const APIURL = process.env.REACT_APP_APIURL;


function InnsendingLokasjoner() {
    const { orgnr } = useParams();
    const token = sessionStorage.getItem("jvtoken") || "";
    const [isLoading, setisLoading] = useState(true);
    const [isLoadingInfo, setisLoadingInfo] = useState("");
    const [virksomheter, setVirksomheter] = useState<any[]>([]);
    const [undereenheter, setUnderenheter] = useState<any[]>([]);
    const [underenhetermangler, setUnderenhetermangler] = useState<any[]>([]);
    const [ModalNyAktor, setModalNyAktor] = useState(false);
    const [openSnack, setOpenSnack] = useState(false);
    const [openSnackError, setOpenSnackError] = useState(false);
    const [snackMelding, setSnackMelding] = useState("");

    const handleCloseSnack = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnack(false);
    }

    const handleCloseSnackError = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackError(false);
    }

    const ModalNyAktorLukk = () => {
        setModalNyAktor(false);
    };

    const ModalNyAktorAapne = () => {
        setModalNyAktor(true);
    };

    const hentVirksomheter = () => {
        setisLoading(true);
        setisLoadingInfo("Henter informasjon om lokasjoner...");
        axios.get(APIURL + "/innsending/lokasjoner/" + orgnr, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setVirksomheter(response.data);
                    setUnderenheter(response.data[0].underenheter);
                    setUnderenhetermangler(response.data[0].underenhetermangler);
                }
                setisLoading(false);
            })
            .catch((e: any) => {
                if ((e.status === 400) || (e.status === 403)) {
                    setSnackMelding(e.response.data.message);
                    setOpenSnackError(true);
                }
                console.log(e);
                setisLoading(false);
            });
    };

    const registrerLokasjon = (orgnr: any) => {
        setisLoading(true);
        setisLoadingInfo("Registrerer ny underenhet/lokasjon...");
        axios.post(APIURL + "/innsending/nylokasjon", {
            orgnr: orgnr,
        }, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                ModalNyAktorLukk();
                hentVirksomheter();
            })
            .catch((e: any) => {
                ModalNyAktorLukk();
                if ((e.status === 400) || (e.status === 403)) {
                    setSnackMelding(e.response.data.message);
                    setOpenSnackError(true);
                }
                console.log(e);
                setisLoading(false);
            });
    };

    useEffect(() => {
        document.title = 'DinSide - Justervesenet';
        hentVirksomheter();
        // eslint-disable-next-line
    }, []);

    return (
        <main>
            {
                isLoading ?
                    <div>
                        <Backdrop
                            open={isLoading}
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        >
                            <CircularProgress color="inherit" /> &nbsp; &nbsp; {isLoadingInfo}
                        </Backdrop >
                    </div>
                    :
                    <div>
                        <ToppMeny />
                        <Breadcrumbs aria-label="breadcrumb" sx={{ padding: 1 }}>
                            <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="inherit"
                                href="/forsiden"
                            >
                                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                <Text tid="forsiden" />
                            </Link>
                            <Typography
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="text.primary"
                            >
                                Innmeldinger
                            </Typography>
                        </Breadcrumbs>
                        <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
                            <Alert onClose={handleCloseSnack} severity="success" sx={{ width: '100%' }}>
                                {snackMelding}
                            </Alert>
                        </Snackbar>
                        <Snackbar open={openSnackError} autoHideDuration={6000} onClose={handleCloseSnackError} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
                            <Alert onClose={handleCloseSnackError} severity="error" sx={{ width: '100%' }}>
                                {snackMelding}
                            </Alert>
                        </Snackbar>

                        <Dialog open={ModalNyAktor} onClose={ModalNyAktorLukk} aria-labelledby="simple-dialog-title" aria-describedby="alert-dialog-description" maxWidth="lg" fullWidth>
                            <DialogTitle id="simple-dialog-title">Registrer ny underenhet/lokasjon</DialogTitle>
                            <DialogContent>
                                {underenhetermangler && underenhetermangler.map(({ orgnr, navn, forretningsadr, forradrpostnr, forradrsted, MetricAktorId }: any, index: number) => {
                                    return <span key={index}>
                                        <Link
                                            href={"#"}
                                            underline="none"
                                        >
                                            <Card variant="outlined" sx={{ mb: 2 }}>
                                                <CardContent>
                                                    <Typography variant="h6" color="text.secondary" gutterBottom>
                                                        {navn} &nbsp; [{orgnr}]
                                                    </Typography>
                                                    <Typography variant="body1" color="text.secondary" className='jvtypeicon' sx={{ mb: 2 }}>
                                                        <HomeIcon className='jvtexticon' /> {forretningsadr} {forradrpostnr} {forradrsted}
                                                    </Typography>
                                                    <Button variant="outlined" size="small" color="primary" onClick={() => {
                                                        registrerLokasjon(orgnr);
                                                    }}>
                                                        Registrer lokasjon
                                                    </Button>
                                                </CardContent>
                                            </Card>
                                        </Link>
                                    </span>
                                })
                                }
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={ModalNyAktorLukk}>Lukk</Button>
                            </DialogActions>
                        </Dialog>

                        <Grid container spacing={2} p={2}>
                            <Grid size={12}>
                                <Typography variant="h6" component="h2" className='jvcardtitle'>
                                    <BusinessIcon className='jvcardtitleicon' /> {virksomheter[0]?.Navn}
                                </Typography>
                            </Grid>
                            <Grid size={12}>
                                <Typography variant="h6" component="h2" className='jvcardtitle'>
                                    Underenheter
                                </Typography>
                                {((underenhetermangler) && (underenhetermangler.length > 0)) && (
                                    <>
                                        <Alert severity="info">
                                            Det er {underenhetermangler.length} underenheter registrert i brønnøysund som ikke er registrert hos Justervesenet.<p></p>
                                            <Button variant="outlined" size="small" color="primary" onClick={() => {
                                                ModalNyAktorAapne();
                                            }}>
                                                Opprett ny underenhet/lokasjon
                                            </Button>
                                        </Alert>
                                    </>
                                )}
                                <p></p>
                                {undereenheter && undereenheter.map(({ OrgNummer, Navn, Adresse, AktorId, Aktiv, AntallInstrumenter }: any, index: number) => {
                                    return <span key={index}>
                                        <Link
                                            href={"/innsending/" + AktorId}
                                            underline="none"
                                        >
                                            <Card variant="outlined" className={(Aktiv) ? "CardLinkA" : "CardLinkNA"} sx={{ mb: 2 }}>
                                                <CardContent>
                                                    <Typography variant="h6" color="text.secondary" gutterBottom>
                                                        {Navn} &nbsp; [{OrgNummer}]
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary" className='jvtypeicon' gutterBottom>
                                                        <HomeIcon className='jvtexticon' /> {Adresse}
                                                    </Typography>
                                                    <p></p>
                                                    {(AktorId) ?
                                                        (Aktiv) ?
                                                            <Typography variant="body2" color="text.secondary" className='jvtypeicon'>
                                                                <CheckBoxIcon className='jvtexticon' color="success" /> Registrert og aktiv hos Justervesenet
                                                            </Typography>
                                                            :
                                                            <Typography variant="body2" color="text.secondary" className='jvtypeicon'>
                                                                <CheckBoxIcon className='jvtexticon' color="error" /> Registrert hos Justervesenet, men ikke aktiv
                                                            </Typography>
                                                        :
                                                        <Typography variant="body2" color="text.secondary" className='jvtypeicon'>
                                                            <DisabledByDefaultIcon className='jvtexticon' color="error" /> Ikke registrert hos Justervesenet
                                                        </Typography>
                                                    }
                                                </CardContent>
                                            </Card>
                                        </Link>
                                    </span>
                                })
                                }
                            </Grid>
                        </Grid>




                    </div>
            }
        </main >
    )
}

export default InnsendingLokasjoner;