import { Alert, Backdrop, Box, Breadcrumbs, Button, Card, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, IconButton, Input, InputLabel, Link, Snackbar, Tooltip, Typography, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ToppMeny from '../komponenter/Toppmeny'
import { Text } from '../komponenter/Tekst'
import axios from 'axios';
import PinInput from 'react-pin-input';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import AccountCircle from '@mui/icons-material/AccountCircle';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import HomeIcon from '@mui/icons-material/Home';
import BusinessIcon from '@mui/icons-material/Business';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckIcon from '@mui/icons-material/Check';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import ListAltIcon from '@mui/icons-material/ListAlt';
import VerifiedIcon from '@mui/icons-material/Verified';
import PersonIcon from '@mui/icons-material/Person';
import LockPersonIcon from '@mui/icons-material/LockPerson';

const APIURL = process.env.REACT_APP_APIURL;

function Profil() {
    const [isLoading, setisLoading] = useState(true);
    const [isLoadingAktorSok, setisLoadingAktorSok] = useState(false);
    const [openSnack, setOpenSnack] = useState(false);
    const [openSnackError, setOpenSnackError] = useState(false);
    const [snackMelding, setSnackMelding] = useState("");
    const [profileModal, setProfileModal] = useState(false);
    const [passwordModal, setPasswordModal] = useState(false);
    const [bekreftEpostModal, setBekreftEpostModal] = useState(false);
    const [bekreftMobilModal, setBekreftMobilModal] = useState(false);
    const [aktorSlettModal, setaktorSlettModal] = useState(false);
    const [aktorTilknyttModal, setaktorTilknyttModal] = useState(false);
    const [profilOppdatert, setProfilOppdatert] = useState(false);
    const [epostBekreftelseSendt, setEpostBekreftelseSendt] = useState(false);
    const [mobilBekreftelseSendt, setMobilBekreftelseSendt] = useState(false);
    const [aktorSlettAktorId, setaktorSlettAktorId] = useState(0);
    const [aktorSlettAktorNavn, setaktorSlettAktorNavn] = useState(null);
    const [jvbruker, setjvbruker] = useState(sessionStorage.getItem('jvbruker'));
    const [jvbrukerverifisert, setjvbrukerverifisert] = useState(false);
    const [jvnavn, setjvnavn] = useState(sessionStorage.getItem('jvnavn'));
    const [jvmobilnummer, setjvmobilnummer] = useState(sessionStorage.getItem('jvmobilnummer'));
    const [jvmobilnummerverifisert, setjvmobilnummerverifisert] = useState(false);
    const jvsesaktorid = sessionStorage.getItem('jvaktorid');
    const [pinEpost, setPinEpost] = useState('');
    const [pinEpostFeil, setPinEpostFeil] = useState(false);
    const [pinMobil, setPinMobil] = useState('');
    const [pinMobilFeil, setPinMobilFeil] = useState(false);
    const [openAdminDialog, setOpenAdminDialog] = useState(false);
    const [aktivtOrgNummer, setAktivtOrgNummer] = useState(0);

    const [aktorsok, setAktorsok] = useState<any[]>([]);

    const handleCloseProfileUpdated = () => {
        setProfilOppdatert(false);
    };

    const [aktorer, setAktorer] = useState<any[]>([]);

    const token = sessionStorage.getItem("jvtoken") || "";

    const handlePinChange = (value: string) => {
        const sanitizedValue = value.replace(/[^0-9]/g, '');
        setPinEpost(sanitizedValue);
    };

    const handlePinChangeMobil = (value: string) => {
        const sanitizedValue = value.replace(/[^0-9]/g, '');
        setPinMobil(sanitizedValue);
    };

    const navigate = useNavigate(); // Brukes for å navigere til ny side

    // Funksjon for å åpne "Bli administrator"-dialogen med orgnr til aktør
    const handleOpenAdminDialog = (orgnr: number) => {
        setAktivtOrgNummer(orgnr);
        setOpenAdminDialog(true);
    };

    // Funksjon for å lukke "Bli administrator"-dialogen
    const handleCloseAdminDialog = () => {
        setOpenAdminDialog(false);
    };

    // Funksjon for å navigere til "Administrer tilganger"-siden
    const handleManageAccess = (OrgNummer: string) => {
        navigate(`/admin/${OrgNummer}`); // Naviger til den nye siden med riktig URL
    };

    const handleClickProfileModalClose = () => {
        setProfileModal(false);
    };

    const handleClickProfileModalOpen = () => {
        setProfileModal(true);
    };

    const handleClickPasswordModalOpen = () => {
        setPasswordModal(true);
    };

    const handleClickPasswordModalClose = () => {
        setPasswordModal(false);
    };

    const handleBekreftEpostModalOpen = () => {
        setBekreftEpostModal(true);
    };

    const handleBekreftEpostModalClose = () => {
        setBekreftEpostModal(false);
    };

    const handleBekreftMobilModalOpen = () => {
        setBekreftMobilModal(true);
    };

    const handleBekreftMobilModalClose = () => {
        setBekreftMobilModal(false);
    };

    const handleOpenModalNyAktor = () => {
        setaktorTilknyttModal(true);
    };

    const handleCloseModalNyAktor = () => {
        setaktorTilknyttModal(false);
    };

    const handleClickaktorSlettModalClose = () => {
        setaktorSlettModal(false);
    };

    const handleClickaktorSlettModalOpen = (aktorid: number, aktornavn: any) => {
        setaktorSlettAktorId(aktorid);
        setaktorSlettAktorNavn(aktornavn);
        setaktorSlettModal(true);
    };

    const handleClickAktivAktor = (aktorid: any, aktornavn: any, aktororgnr: any) => {
        sessionStorage.setItem('jvaktorid', aktorid);
        sessionStorage.setItem('jvaktornavn', aktornavn);
        sessionStorage.setItem('jvaktororgnummer', aktororgnr);
        getaktorer();
    }

    const handleCloseSnack = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnack(false);
    }

    const handleCloseSnackError = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackError(false);
    }

    const handleSubmitPassword = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setisLoading(true);
        handleClickPasswordModalClose();
        var data = new FormData(event.currentTarget);
        var formdata = {
            passord: data.get('passord')
        };
        axios.post(APIURL + "/passord", formdata, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setProfilOppdatert(true);
                }
                else {
                }
                setisLoading(false);
            })
            .catch((e: any) => {
                if ((e.response.status === 401) || (e.response.status === 403)) {
                    sessionStorage.clear();
                    window.location.assign("/");
                }
                setisLoading(false);
            });
    }

    const handleSubmitSendAdminBrev = () => {
        setisLoading(true);
        var formdata = {
            orgnr: aktivtOrgNummer,
        };
        axios.post(APIURL + "/aktoradmin/aktorpassordbrev", formdata, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if ((response.status === 200) && (response.data === 1)) {
                    setSnackMelding("Melding med passord sendt til Altinn.");
                    setOpenSnack(true);
                }
                if ((response.status === 200) && (response.data === 2)) {
                    setSnackMelding("Feil under sending av melding med passord. Det er allerede sendt en melding med passord til Altinn i løpet av de siste 3 ukene.");
                    setOpenSnackError(true);
                }
                if ((response.status === 200) && (response.data === 0)) {
                    setSnackMelding("Feil under sending av melding med passord.");
                    setOpenSnackError(true);
                }
                setisLoading(false);
            })
            .catch((e: any) => {
                if ((e.response.status === 401) || (e.response.status === 403)) {
                    sessionStorage.clear();
                    window.location.assign("/");
                }
                setisLoading(false);
            });
    }

    const handleSubmitProfile = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setisLoading(true);
        handleClickProfileModalClose();
        var data = new FormData(event.currentTarget);
        const nymobil = data.get('Mobilnummer');
        const nyepost = data.get('Epost');

        if (nymobil !== jvmobilnummer) {
            sessionStorage.setItem('jvmobilnummerverifisert', false.toString());
            setjvmobilnummerverifisert(false);
        }

        if (nyepost !== jvbruker) {
            sessionStorage.setItem('jvbrukerverifisert', false.toString());
            setjvbrukerverifisert(false);
        }

        var formdata = {
            Navn: data.get('Navn'),
            Epost: data.get('Epost'),
            Mobilnummer: data.get('Mobilnummer')
        };
        axios.put(APIURL + "/profil", formdata, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setProfilOppdatert(true);
                    sessionStorage.setItem('jvbruker', response.data[0].epost);
                    sessionStorage.setItem('jvnavn', response.data[0].navn);
                    sessionStorage.setItem('jvmobilnummer', response.data[0].mobilnummer);
                    setjvbruker(response.data[0].epost);
                    setjvnavn(response.data[0].navn);
                    setjvmobilnummer(response.data[0].mobilnummer);
                }
                else {
                }
                setisLoading(false);
            })
            .catch((e: any) => {
                if ((e.response.status === 401) || (e.response.status === 403)) {
                    sessionStorage.clear();
                    window.location.assign("/");
                }
                setisLoading(false);
            });
    }

    const handleSubmitEpostBekreftelse = () => {
        setEpostBekreftelseSendt(true);
        setisLoading(true);
        var formdata = {};
        axios.post(APIURL + "/profil/bekreftepost", formdata, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                }
                else {
                }
                setisLoading(false);
            })
            .catch((e: any) => {
                setisLoading(false);
            });
    }

    const handleSubmitEpostBekreftelseKode = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setisLoading(true);
        var formdata = {
            pinkode: pinEpost,
        };
        axios.post(APIURL + "/profil/bekreftepostkode", formdata, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    if (response.data === 1) {
                        setPinEpostFeil(false);
                        setjvbrukerverifisert(true);
                        sessionStorage.setItem('jvbrukerverifisert', "true");
                        handleBekreftEpostModalClose();
                        setSnackMelding("E-post verifisert OK.");
                        setOpenSnack(true);
                    }
                    else {
                        setPinEpostFeil(true);
                        setSnackMelding("Feil PIN-kode for verifisering av e-post oppgitt.");
                        setOpenSnackError(true);
                    }
                }
                else {
                }
                setisLoading(false);
            })
            .catch((e: any) => {
                if ((e.response.status === 401) || (e.response.status === 403)) {
                    sessionStorage.clear();
                    window.location.assign("/");
                }
                setisLoading(false);
            });
    }

    const handleSubmitMobilBekreftelseKode = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setisLoading(true);
        var formdata = {
            pinkode: pinMobil,
        };
        axios.post(APIURL + "/profil/bekreftmobilkode", formdata, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    if (response.data === 1) {
                        setPinMobilFeil(false);
                        setjvmobilnummerverifisert(true);
                        sessionStorage.setItem('jvmobilnummerverifisert', "true");
                        handleBekreftMobilModalClose();
                        setSnackMelding("Mobilnummer verifisert OK.");
                        setOpenSnack(true);
                    }
                    else {
                        setPinMobilFeil(true);
                        setSnackMelding("Feil PIN-kode for verifisering av mobilnumer oppgitt.");
                        setOpenSnackError(true);
                    }
                }
                else {
                }
                setisLoading(false);
            })
            .catch((e: any) => {
                if ((e.response.status === 401) || (e.response.status === 403)) {
                    sessionStorage.clear();
                    window.location.assign("/");
                }
                setisLoading(false);
            });
    }

    const handleSubmitMobilBekreftelse = () => {
        setMobilBekreftelseSendt(true);
        setisLoading(true);
        var formdata = {};
        axios.post(APIURL + "/profil/bekreftmobil", formdata, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                }
                else {
                }
                setisLoading(false);
            })
            .catch((e: any) => {
                setisLoading(false);
            });
    }

    const handleSubmitTilknyttAktor = (orgnr: number) => {
        setisLoading(true);
        handleCloseModalNyAktor();
        var formdata = {
            orgnr: orgnr,
        };
        axios.post(APIURL + "/profil/aktortilknytt", formdata, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setProfilOppdatert(true);
                    sessionStorage.setItem('jvbruker', response.data.epost);
                    sessionStorage.setItem('jvbrukerverifisert', response.data.epostverifisert);
                    sessionStorage.setItem('jvnavn', response.data.navn);
                    sessionStorage.setItem('jvmobilnummer', response.data.mobilnummer);
                    sessionStorage.setItem('jvmobilnummerverifisert', response.data.mobilnummerverifisert);
                    sessionStorage.setItem('jvtoken', response.data.accesstoken);
                    sessionStorage.setItem('jvaktorid', response.data.aktorid);
                    sessionStorage.setItem('jvaktornavn', response.data.aktornavn);
                    sessionStorage.setItem('jvaktororgnummer', response.data.aktororgnr);
                    sessionStorage.setItem('jvaktorverifisert', response.data.aktorverifisert);
                    sessionStorage.setItem('jvaktortaxiservicefirma', response.data.taxiservicefirma);
                    getaktorer();
                }
                else {
                }
                setisLoading(false);
            })
            .catch((e: any) => {
                if ((e.response?.status === 401) || (e.response?.status === 403)) {
                    sessionStorage.clear();
                    window.location.assign("/");
                }
                setisLoading(false);
            });
    }

    /* Oppdaterer aktøren til å bli administrator */
    const handleSubmitAdminTilgang = (event: React.FormEvent<HTMLFormElement>) => {
        setisLoading(true);
        var data = new FormData(event.currentTarget);
        var formdata = {
            orgnr: aktivtOrgNummer,
            passord: data.get('passord')
        };
        axios.post(APIURL + "/profil/aktoradmin", formdata, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if ((response.status === 200) && (response.data === 1)) {
                    handleCloseAdminDialog();
                    getaktorer();
                    setSnackMelding("Passord godkjent, du er nå administrator for organisasjonsnummer " + aktivtOrgNummer + ". Du kan nå administrere informasjon og tilganger på aktøren.");
                    sessionStorage.setItem('jvaktorverifisert', "true");
                    setOpenSnack(true);
                }
                else {
                    setSnackMelding("Passordet du oppgav er feil.");
                    setOpenSnackError(true);
                }
                setisLoading(false);
            })
            .catch((e: any) => {
                if ((e.response.status === 401) || (e.response.status === 403)) {
                    sessionStorage.clear();
                    window.location.assign("/");
                }
                setisLoading(false);
            });
    }


    const handleSokAktor = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setisLoadingAktorSok(true);
        var data = new FormData(event.currentTarget);
        var formdata = {
            aktorsok: data.get('aktorsok'),
        };
        axios.post(APIURL + "/profil/aktorsok", formdata, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setAktorsok(response.data);
                }
                else {
                }
                setisLoadingAktorSok(false);
            })
            .catch((e: any) => {
                if ((e.response.status === 401) || (e.response.status === 403)) {
                    sessionStorage.clear();
                    window.location.assign("/");
                }
                setisLoadingAktorSok(false);
            });
    }

    const handleSubmitAktorSlett = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setisLoading(true);
        handleClickaktorSlettModalClose();
        var formdata = {
            AktorId: aktorSlettAktorId,
        };
        await axios.post(APIURL + "/profil/aktorslett", formdata, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setProfilOppdatert(true);
                    sessionStorage.setItem('jvbruker', response.data.epost);
                    sessionStorage.setItem('jvbrukerverifisert', response.data.epostverifisert);
                    sessionStorage.setItem('jvnavn', response.data.navn);
                    sessionStorage.setItem('jvmobilnummer', response.data.mobilnummer);
                    sessionStorage.setItem('jvmobilnummerverifisert', response.data.mobilnummerverifisert);
                    sessionStorage.setItem('jvtoken', response.data.accesstoken);
                    sessionStorage.setItem('jvaktorid', response.data.aktorid);
                    sessionStorage.setItem('jvaktornavn', response.data.aktornavn);
                    sessionStorage.setItem('jvaktororgnummer', response.data.aktororgnr);
                    sessionStorage.setItem('jvaktorverifisert', response.data.aktorverifisert);
                    sessionStorage.setItem('jvaktortaxiservicefirma', response.data.taxiservicefirma);
                    getaktorer();
                }
                else {
                }
                setisLoading(false);
            })
            .catch((e: any) => {
                if ((e.response?.status === 401) || (e.response?.status === 403)) {
                    sessionStorage.clear();
                    window.location.assign("/");
                }
                setisLoading(false);
            });
    }

    function getaktorer(setaktor?: number | undefined) {
        axios.get(APIURL + "/profil/aktorer", { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setAktorer(response.data);
                    if (setaktor === 1) {
                        sessionStorage.setItem('jvaktorid', response.data[0].AktorId);
                        sessionStorage.setItem('jvaktornavn', response.data[0].Navn);
                        sessionStorage.setItem('jvaktororgnummer', response.data[0].OrgNummer);
                    }
                }
            })
            .catch((e: any) => {
                if ((e.response?.status === 401) || (e.response?.status === 403)) {
                    sessionStorage.clear();
                    window.location.assign("/");
                }
            });
    }

    useEffect(() => {
        document.title = 'Din Side - Justervesenet';
        getaktorer();
        const jvbrukerverifisert = sessionStorage.getItem('jvbrukerverifisert');
        const isVerified = jvbrukerverifisert === 'true';
        setjvbrukerverifisert(isVerified);
        const jvmobilnummerverifisert = sessionStorage.getItem('jvmobilnummerverifisert');
        const isVerifiedMobil = jvmobilnummerverifisert === 'true';
        setjvmobilnummerverifisert(isVerifiedMobil);
        setisLoading(false);
        // eslint-disable-next-line
    }, []);

    return (
        <main>
            {
                isLoading ?
                    <div>
                        <Backdrop
                            open={isLoading}
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop >
                    </div>
                    :
                    <div>
                        <ToppMeny />
                        <Breadcrumbs aria-label="breadcrumb" sx={{ padding: 1 }}>
                            <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="inherit"
                                href="/forsiden"
                            >
                                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                <Text tid="forsiden" />
                            </Link>
                            <Typography
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="text.primary"
                            >
                                <Text tid="minprofil" />
                            </Typography>
                        </Breadcrumbs>
                        <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
                            <Alert onClose={handleCloseSnack} severity="success" sx={{ width: '100%' }}>
                                {snackMelding}
                            </Alert>
                        </Snackbar>
                        <Snackbar open={openSnackError} autoHideDuration={6000} onClose={handleCloseSnackError} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
                            <Alert onClose={handleCloseSnackError} severity="error" sx={{ width: '100%' }}>
                                {snackMelding}
                            </Alert>
                        </Snackbar>
                        <Snackbar open={profilOppdatert} autoHideDuration={6000} onClose={handleCloseProfileUpdated} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
                            <Alert onClose={handleCloseProfileUpdated} severity="success" sx={{ width: '100%' }}>
                                <Text tid="profiloppdatert" />
                            </Alert>
                        </Snackbar>
                        <Dialog
                            open={profileModal}
                            onClose={handleClickProfileModalClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                <Text tid="endreprofil" />
                            </DialogTitle>
                            <Box component="form" onSubmit={handleSubmitProfile}>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        <FormControl fullWidth sx={{ mb: 2 }} variant="standard" >
                                            <InputLabel htmlFor="navn"><Text tid="fulltnavn" /></InputLabel>
                                            <Input
                                                autoFocus
                                                id="Navn"
                                                name="Navn"
                                                defaultValue={jvnavn}
                                                fullWidth
                                                required
                                            />
                                        </FormControl>
                                        <FormControl fullWidth sx={{ mb: 2 }} variant="standard">
                                            <InputLabel htmlFor="Epost"><Text tid="epost" /></InputLabel>
                                            <Input
                                                id="Epost"
                                                name="Epost"
                                                defaultValue={jvbruker}
                                                fullWidth
                                                required
                                            />
                                        </FormControl>
                                        <FormControl fullWidth sx={{ mb: 2 }} variant="standard">
                                            <InputLabel htmlFor="Mobilnummer"><Text tid="mobilnummer" /></InputLabel>
                                            <Input
                                                id="Mobilnummer"
                                                name="Mobilnummer"
                                                defaultValue={jvmobilnummer}
                                                fullWidth
                                                required
                                            />
                                        </FormControl>
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        size="small"
                                        type="submit"
                                        variant="outlined"
                                        color="success"
                                    >
                                        <SaveIcon className='jvbtnicon' />
                                        <Text tid="lagre" />
                                    </Button>
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        color="error"
                                        onClick={handleClickProfileModalClose}
                                    >
                                        <CloseIcon className='jvbtnicon' />
                                        <Text tid="avbryt" />
                                    </Button>
                                </DialogActions>
                            </Box>
                        </Dialog>
                        <Dialog
                            open={passwordModal}
                            onClose={handleClickPasswordModalClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                <Text tid="endrepassord" />
                            </DialogTitle>
                            <Box component="form" onSubmit={handleSubmitPassword}>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        <FormControl fullWidth sx={{ mb: 2 }} variant="standard" >
                                            <InputLabel htmlFor="navn"><Text tid="passord" /></InputLabel>
                                            <Input autoFocus id="passord" name="passord" type="password" fullWidth required />
                                        </FormControl>
                                        <FormControl fullWidth sx={{ mb: 2 }} variant="standard">
                                            <InputLabel htmlFor="Epost"><Text tid="bekreftpassord" /></InputLabel>
                                            <Input id="password2" name="bekreftpassord" type="password" fullWidth required />
                                        </FormControl>
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button size="small" type="submit" variant="outlined" color="success">
                                        <SaveIcon className='jvbtnicon' />
                                        <Text tid="lagre" />
                                    </Button>
                                    <Button size="small" variant="outlined" color="error" onClick={handleClickPasswordModalClose}>
                                        <CloseIcon className='jvbtnicon' />
                                        <Text tid="avbryt" />
                                    </Button>
                                </DialogActions>
                            </Box>
                        </Dialog>
                        <Dialog
                            open={aktorSlettModal}
                            onClose={handleClickaktorSlettModalClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                <Text tid="aktortilknyttfjern" />
                            </DialogTitle>
                            <Box component="form" onSubmit={handleSubmitAktorSlett}>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        <Text tid="aktortilknyttfjernbekreftelse" /> {aktorSlettAktorNavn}?
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        size="small"
                                        type="submit"
                                        variant="outlined"
                                        color="success"
                                    >
                                        <CheckIcon className='jvbtnicon' />
                                        <Text tid="bekreft" />
                                    </Button>
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        color="error"
                                        onClick={handleClickaktorSlettModalClose}
                                    >
                                        <CloseIcon className='jvbtnicon' />
                                        <Text tid="avbryt" />
                                    </Button>
                                </DialogActions>
                            </Box>
                        </Dialog>
                        <Dialog
                            open={aktorTilknyttModal}
                            onClose={handleCloseModalNyAktor}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            maxWidth="md"
                            fullWidth
                        >
                            <DialogTitle id="alert-dialog-title">
                                <Text tid="tilknyttnyaktor" />
                            </DialogTitle>
                            <Box component="form" onSubmit={handleSokAktor} noValidate>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        <Typography variant="body2" color="text.secondary" className='jvtypeicon' gutterBottom sx={{ mb: 2 }}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Alert severity="info">
                                                        <Text tid='ainfosok' />
                                                    </Alert>
                                                </Grid>
                                            </Grid>
                                        </Typography>
                                        <Grid container>
                                            <Grid item xs={8}>
                                                <FormControl fullWidth sx={{ mb: 2 }} variant="standard" >
                                                    <InputLabel htmlFor="navn"><Text tid="sokaktororg" /></InputLabel>
                                                    <Input
                                                        autoFocus
                                                        id="aktorsok"
                                                        name="aktorsok"
                                                        fullWidth
                                                        required
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={4} pl={2} pt={2}>
                                                <Button
                                                    type="submit"
                                                    size="small"
                                                    variant="outlined"
                                                    color="success"
                                                >
                                                    <SearchIcon className='jvbtnicon' />
                                                    <Text tid="sok" />
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        {isLoadingAktorSok ?
                                            <div>
                                                <Backdrop
                                                    open={isLoadingAktorSok}
                                                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
                                                >
                                                    <CircularProgress color="inherit" />
                                                </Backdrop >
                                            </div>
                                            :
                                            null
                                        }
                                        {aktorsok && aktorsok.map(({ navn, orgnr }: any, index: number) => {
                                            return <span key={orgnr}>
                                                <Card variant="outlined" sx={{ mb: 2 }}>
                                                    <CardContent>
                                                        <Typography variant="h6" color="text.secondary" gutterBottom>
                                                            {navn}
                                                        </Typography>

                                                        <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            <Typography variant="h6" color="text.secondary" gutterBottom className='jvtypeicon'>
                                                                {orgnr}
                                                            </Typography>
                                                            <Button variant="outlined" color="success" size="small" onClick={e => handleSubmitTilknyttAktor(orgnr)}><AddIcon className='jvbtnicon' /> <Text tid="tilknytt" /></Button>
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            </span>
                                        })
                                        }
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        color="error"
                                        onClick={handleCloseModalNyAktor}
                                    >
                                        <CloseIcon className='jvbtnicon' />
                                        <Text tid="avbryt" />
                                    </Button>
                                </DialogActions>
                            </Box>
                        </Dialog>

                        <Dialog
                            fullWidth
                            maxWidth="lg"
                            open={bekreftEpostModal}
                            onClose={handleBekreftEpostModalClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                <Text tid="verifiserepostadresse" />
                            </DialogTitle>
                            <Box component="form" onSubmit={handleSubmitEpostBekreftelseKode}>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        <Text tid="verifiserepostinfo" />
                                        <p></p><Text tid="epost" />: <b>{jvbruker}</b>
                                        <p></p>
                                        <Button
                                            size="small"
                                            variant="outlined"
                                            color="primary"
                                            onClick={handleSubmitEpostBekreftelse}
                                            disabled={epostBekreftelseSendt}
                                        >
                                            <Text tid="sendmegepostkode" />
                                        </Button>
                                        {epostBekreftelseSendt &&
                                            <div>
                                                <Typography variant="caption" color="text.secondary" sx={{ pt: 2 }}>
                                                    <Text tid="epostkodesendt" />
                                                </Typography>
                                            </div>
                                        }
                                        <p></p>
                                        <Typography variant="h6" color="text.secondary" className='jvcardtitle' sx={{ pt: 2 }}>
                                            <LockPersonIcon className='jvcardtitleicon' /> <Text tid="bekreftepostkode" />
                                        </Typography>
                                        <PinInput
                                            length={6}
                                            initialValue=""
                                            secret
                                            secretDelay={1000}
                                            onChange={(value, index) => handlePinChange(value)}
                                            type="numeric"
                                            inputMode="number"
                                            style={{
                                                padding: '2px',
                                            }}
                                            inputStyle={{
                                                fontSize: '24px',
                                            }}
                                            inputFocusStyle={{
                                                background: '#eeeeee'
                                            }}
                                            autoSelect={true}
                                            regexCriteria={/^[0-9]*$/}
                                        />
                                        {pinEpostFeil &&
                                            <Alert severity="error" sx={{ mt: 2 }}>
                                                <Text tid="feilepostpin" />
                                            </Alert>
                                        }
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        size="small"
                                        type="submit"
                                        variant="outlined"
                                        color="success"
                                    >
                                        <SaveIcon className='jvbtnicon' />
                                        <Text tid="bekreftepostpinknapp" />
                                    </Button>
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        color="error"
                                        onClick={handleBekreftEpostModalClose}
                                    >
                                        <CloseIcon className='jvbtnicon' />
                                        <Text tid="avbryt" />
                                    </Button>
                                </DialogActions>
                            </Box>
                        </Dialog>

                        <Dialog
                            open={bekreftMobilModal}
                            onClose={handleBekreftMobilModalClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                <Text tid="verifisermobil" />
                            </DialogTitle>
                            <Box component="form" onSubmit={handleSubmitMobilBekreftelseKode}>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        <Text tid="mobilsendkodeinfo" />
                                        <p></p><Text tid="mobilnummer" />: <b>{jvmobilnummer}</b>
                                        <p></p>
                                        <Button
                                            size="small"
                                            variant="outlined"
                                            color="primary"
                                            onClick={handleSubmitMobilBekreftelse}
                                            disabled={mobilBekreftelseSendt}
                                        >
                                            <Text tid="sendkodesmsknapp" />
                                        </Button>
                                        {mobilBekreftelseSendt &&
                                            <div>
                                                <Typography variant="caption" color="text.secondary" sx={{ pt: 2 }}>
                                                    <Text tid="smspinsendttil" /> {jvmobilnummer}.
                                                </Typography>
                                            </div>
                                        }
                                        <p></p>
                                        <Typography variant="h6" color="text.secondary" className='jvcardtitle' sx={{ pt: 2 }}>
                                            <LockPersonIcon className='jvcardtitleicon' /> <Text tid="bekreftsmskode" />
                                        </Typography>
                                        <PinInput
                                            length={6}
                                            initialValue=""
                                            secret
                                            secretDelay={1000}
                                            onChange={(value, index) => handlePinChangeMobil(value)}
                                            type="numeric"
                                            inputMode="number"
                                            style={{
                                                padding: '2px',
                                            }}
                                            inputStyle={{
                                                fontSize: '24px',
                                            }}
                                            inputFocusStyle={{
                                                background: '#eeeeee'
                                            }}
                                            autoSelect={true}
                                            regexCriteria={/^[0-9]*$/}
                                        />
                                        {pinMobilFeil &&
                                            <Alert severity="error" sx={{ mt: 2 }}>
                                                <Text tid="feilsmspin" />
                                            </Alert>
                                        }
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        size="small"
                                        type="submit"
                                        variant="outlined"
                                        color="success"
                                    >
                                        <SaveIcon className='jvbtnicon' />
                                        <Text tid="bekreftsmspin" />
                                    </Button>
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        color="error"
                                        onClick={handleBekreftMobilModalClose}
                                    >
                                        <CloseIcon className='jvbtnicon' />
                                        <Text tid="avbryt" />
                                    </Button>
                                </DialogActions>
                            </Box>
                        </Dialog>

                        <Grid container spacing={2} p={2}>
                            <Grid item xs={12}>
                                {((jvsesaktorid === "undefined") || (jvsesaktorid === null)) &&
                                    <Typography>
                                        <Alert severity="error"><Text tid="manglertilknytning" /><p></p>
                                            <Button variant="outlined" size="small" color="primary" onClick={handleOpenModalNyAktor}>
                                                <AddCircleIcon className='jvbtnicon' />
                                                <Text tid="tilknyttnyaktor" />
                                            </Button>
                                        </Alert>
                                    </Typography>
                                }
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <Typography variant="h6" component="div">
                                    <Typography variant="h6" color="text.secondary" gutterBottom className='jvcardtitle'>
                                        <AccountCircle className='jvcardtitleicon' /> <Text tid="minprofil" />
                                    </Typography>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <Typography variant="h6" color="text.secondary" gutterBottom>
                                                {jvnavn}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary" className='jvtypeicon'>
                                                <EmailIcon className='jvtexticonnc' /> {jvbruker} {(jvbrukerverifisert) && <VerifiedIcon className='jviconverified' sx={{ ml: 1 }} />}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary" className='jvtypeicon'>
                                                <PhoneIcon className='jvtexticonnc' /> {jvmobilnummer} {(jvmobilnummerverifisert) && <VerifiedIcon className='jviconverified' sx={{ ml: 1 }} />}
                                            </Typography>
                                            <p></p>
                                            {(!jvbrukerverifisert) &&
                                                <Alert severity="error"><Text tid="epostikkeverifisert" />
                                                    <p></p>
                                                    <Button variant="outlined" size="small" color="primary" onClick={handleBekreftEpostModalOpen}><CheckIcon className='jvbtnicon' /> <Text tid="verifiserepost" /></Button>
                                                </Alert>
                                            }
                                            <p></p>
                                            {(!jvmobilnummerverifisert) &&
                                                <Alert severity="error"><Text tid="mobilikkeverifisert" /><p></p>
                                                    <Button variant="outlined" size="small" color="primary" onClick={handleBekreftMobilModalOpen}><CheckIcon className='jvbtnicon' /> <Text tid="verifisermobil" /></Button>
                                                </Alert>
                                            }
                                            <p></p>
                                            <Button variant="outlined" color="warning" onClick={handleClickProfileModalOpen} sx={{ mr: 2 }}><EditIcon className='jvbtnicon' /> <Text tid="endreprofil" /></Button>
                                            <Button variant="outlined" color="warning" onClick={handleClickPasswordModalOpen}><EditIcon className='jvbtnicon' /> <Text tid="endrepassord" /></Button>
                                        </CardContent>
                                    </Card>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography variant="h6" color="text.secondary" gutterBottom className='jvcardtitle'>
                                        <BusinessIcon className='jvcardtitleicon' /> <Text tid="aktor" />
                                    </Typography>
                                    <IconButton color="primary" aria-label="Tilknytt ny aktør" onClick={handleOpenModalNyAktor}>
                                        <AddCircleIcon className='jvtitleactionicon' />
                                    </IconButton>
                                </Grid>

                                {/* Itererer gjennom aktørene */}
                                {aktorer.map(({ AktorId, Navn, OrgNummer, Rolle, Verifisert, Admin }: any, index: number) => {
                                    return <span key={AktorId}>
                                        <Card variant="outlined" sx={{ mb: 2 }}>
                                            {!Verifisert &&
                                                <Alert severity="warning">
                                                    <Text tid="ikkeveraktor" />
                                                </Alert>
                                            }
                                            <CardContent>
                                                <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <Typography variant="h6" color="text.secondary" gutterBottom className='jvtypeicon'>
                                                        {(AktorId.toString() === jvsesaktorid) && <Tooltip title={Text({ tid: "ttaktivaktor" })}>
                                                            <DoneOutlineIcon color="success" sx={{ mr: 1 }} /></Tooltip>} {Navn} {(Verifisert === true) &&
                                                                <Tooltip title={Text({ tid: "ttaktorver" })}>
                                                                    <VerifiedIcon className='jviconverified' sx={{ ml: 1 }} />
                                                                </Tooltip>}
                                                    </Typography>
                                                </Grid>
                                                <Typography variant="body2" color="text.secondary" className='jvtypeicon' sx={{ ml: 3 }}>
                                                    <ListAltIcon className='jvtexticonnc' /> {OrgNummer}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary" className='jvtypeicon' sx={{ ml: 3 }}>
                                                    <PersonIcon className='jvtexticonnc' /> {Rolle}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary" className='jvtypeicon' sx={{ ml: 3 }}>
                                                    <LockPersonIcon className='jvtexticonnc' /> {(
                                                        (Admin === true) ?
                                                            "Administrator"
                                                            :
                                                            (Verifisert === true) ?
                                                                "Verifisert"
                                                                :
                                                                <Typography color="red" variant="body2" className='jvtypeicon'>Ikke verifisert</Typography>
                                                    )}
                                                </Typography>
                                                <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
                                                    {(AktorId.toString() !== jvsesaktorid) &&
                                                        <Button variant="outlined" size="small" color="primary" onClick={e => handleClickAktivAktor(AktorId, Navn, OrgNummer)} sx={{ mr: 1 }}><CheckCircleOutlineIcon className='jvbtnicon' />
                                                            <Text tid="settaktiv" /></Button>}
                                                    <Button variant="outlined" size="small" color="error" onClick={e => handleClickaktorSlettModalOpen(AktorId, Navn)}>
                                                        <DeleteForeverIcon className='jvbtnicon' /> <Text tid="sletttilknytning" /></Button>

                                                    {/* Det må legges til logikk for visning av admin-knapper */}
                                                    {(Admin === true) ? (
                                                        <Button
                                                            variant="outlined"
                                                            size="small"
                                                            color="primary"
                                                            onClick={() => handleManageAccess(OrgNummer)} // Bruker orgNummer for å navigere
                                                        >
                                                            <AccountCircle className='jvbtnicon' /> <Text tid="administrertilganger" />
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            variant="outlined"
                                                            size="small"
                                                            color="primary"
                                                            onClick={e => handleOpenAdminDialog(OrgNummer)}
                                                        >
                                                            <AccountCircle className='jvbtnicon' /> <Text tid="bliadministrator" />
                                                        </Button>
                                                    )}
                                                </Box>
                                            </CardContent>
                                        </Card>
                                    </span>
                                })
                                }
                            </Grid>
                        </Grid>
                        {/* Dialogvindu for "Bli administrator" */}
                        <Dialog open={openAdminDialog} onClose={handleCloseAdminDialog}>
                            <Box component="form" onSubmit={handleSubmitAdminTilgang}>
                                <DialogTitle><Text tid="bliadministrator" /></DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        <Typography variant="body1" color="text.secondary" className='jvtypeicon' sx={{ mb: 2 }}>
                                            <Text tid="aktoradmininfo" />
                                        </Typography>
                                        <Button size="small" type="submit" variant="outlined" color="success" sx={{ mb: 2 }} onClick={e => handleSubmitSendAdminBrev()}>
                                            <NavigateNextIcon className='jvbtnicon' /> <Text tid="sendnyttpassord" />
                                        </Button>
                                        <Typography variant="body1" color="text.secondary" className='jvtypeicon'>
                                            <Text tid="fyllinnpassordaltinn" />
                                        </Typography>
                                    </DialogContentText>
                                    <TextField autoFocus name="passord" id="altinn-passord" label="Passord" type="password" fullWidth variant="outlined" sx={{ mt: 2 }} />
                                </DialogContent>
                                <DialogActions>
                                    <Button size="small" type="submit" variant="outlined" color="success">
                                        <NavigateNextIcon className='jvbtnicon' /> <Text tid="sendinn" />
                                    </Button>
                                    <Button variant="outlined" size="small" color="error" onClick={handleCloseAdminDialog}>
                                        <CloseIcon className='jvbtnicon' /> <Text tid="avbryt" />
                                    </Button>
                                </DialogActions>
                            </Box>
                        </Dialog>
                    </div>
            }
        </main>
    )
}

export default Profil;