import { AppBar, Autocomplete, Backdrop, Box, Breadcrumbs, Button, ButtonGroup, 
    Card, CardContent, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, Divider, 
    FormControl, FormControlLabel, FormGroup, FormLabel, Grid, IconButton, InputLabel, Link, List, ListItem, MenuItem, Paper, 
    Radio, RadioGroup, Select, Slide, Snackbar, Tab, Table, TableBody, TableCell, TableContainer, TableHead, 
    TableRow, Tabs, TextField, Toolbar, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState, useRef, forwardRef } from 'react'

import ToppMeny from '../komponenter/Toppmeny'
import { Text } from '../komponenter/Tekst'
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import HomeIcon from '@mui/icons-material/Home';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import PlaceIcon from '@mui/icons-material/Place';
import { useParams } from 'react-router-dom';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import moment from 'moment';
import InfoIcon from '@mui/icons-material/Info';
import BusinessIcon from '@mui/icons-material/Business';
import QrCodeIcon from '@mui/icons-material/QrCode';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import AddIcon from '@mui/icons-material/Add';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { TransitionProps } from '@mui/material/transitions';
import { useFormik } from 'formik';


const APIURL = process.env.REACT_APP_APIURL;

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function InnsendingDetaljer() {
    const { aktorid } = useParams();
    const [valueTab, setValueTab] = React.useState(0);
    const [openSnack, setOpenSnack] = useState(false);
    const [openSnackError, setOpenSnackError] = useState(false);
    const [snackMelding, setSnackMelding] = useState("");
    const [isLoading, setisLoading] = useState(true);
    const [AktivAktorId, setAktivAktorId] = useState(0);
    const [aktor, setAktor] = useState<any[]>([]);
    const token = sessionStorage.getItem("jvtoken") || "";
    const [instrumentType, setInstrumentType] = useState('');
    const [installationDate, setInstallationDate] = useState<string | null>(null);
    const [fabrikat, setFabrikat] = useState('');
    const [modell, setModell] = useState('');
    const [serienummer, setSerienummer] = useState('');
    const [merking, setMerking] = useState('');
    const [maksKapasitet, setMaksKapasitet] = useState('');
    const [maksKapasitetBenevnelse, setMaksKapasitetBenevnelse] = useState('');
    const [delinger, setDelinger] = useState('');
    const [delingerMaaleenhet, setDelingerMaaleenhet]  = useState('');
    const [instrumentdelType, setInstrumentdelType] = useState('');
    const [kategori, setKategori] = useState('');
    const [plombenavn, setPlombenavn] = useState('');
    const [plombetype, setPlombetype] = useState('');
    const [plombenummer, setPlombenummer] = useState('');
    const [plassering, setPlassering] = useState('');
    const [typeprovingssertifikat, setTypeprovingssertifikat] = useState<File | null>(null);
    const [samsvarserlaering, setSamsvarserklaering] = useState<File | null>(null);
    const [filer, setFiler] = useState<File[]>([]);
    


    interface Instrument {
        Merking?: string;
        Fabrikat?: string;
        Modell?: string;
        Instrumenttype: string;
        Serienummer?: string;
        Jvstrekkode?: string;
        OpprettelsesDato?: string;
        SisteMeldingDato?: string;
        TypeprovingssertifikatNr?: string;
        MaksKapasitet?: string;
        Plombenavn?: string;
        Plombetype?: string;
        Plombenummer?: string;
        Plombeplassering?: string;
        Status?: string;
        Instrumentdeltype?: string;
        Kategori?: string;
        Delinger?: string;
    }

    const testdata: Instrument[] = [
        {
            Merking: "Kasse 1",
            Fabrikat: "Bizerba",
            Modell: "CS300",
            Instrumenttype: "Butikkvekt",
            Serienummer: "",
            Jvstrekkode: "133302",
            OpprettelsesDato: "2024-01-15",
            SisteMeldingDato: "2024-10-10",
            TypeprovingssertifikatNr: "TP123456",
            MaksKapasitet: "500kg",
            Plombenavn: "Koblingsboks",
            Plombetype: "Fysisk",
            Plombenummer: "JV-Rundelapp",
            Plombeplassering: "Veieplate",
            Status: "",
            Instrumentdeltype: "Butikkvekt (EN45501)",
            Kategori: "",
            Delinger: "5.00 g"
        },
        {
            Merking: "67890",
            Fabrikat: "Fabrikat B",
            Modell: "Modell Y",
            Instrumenttype: "Butikkvekt",
            Serienummer: "SN789012",
            Jvstrekkode: "",
            OpprettelsesDato: "",
            SisteMeldingDato: "2023-12-01",
            TypeprovingssertifikatNr: "TP789012",
            MaksKapasitet: "600kg",
            Plombenavn: "Koblingsboks",
            Plombetype: "Fysisk",
            Plombenummer: "JV-Rundelapp",
            Plombeplassering: "Veieplate",
            Status: "",
            Instrumentdeltype: "Butikkvekt (EN45501)",
            Kategori: "",
            Delinger: "5.00 g"
        },
        {
            Merking: "67890",
            Fabrikat: "Fabrikat B",
            Modell: "Modell Y",
            Instrumenttype: "Termisk detektor",
            Serienummer: "SN789012",
            Jvstrekkode: "JV123456",
            OpprettelsesDato: "2023-05-20",
            SisteMeldingDato: "2023-12-01",
            TypeprovingssertifikatNr: "TP789012",
            MaksKapasitet: "600kg",
            Status: "",
            Instrumentdeltype: "Butikkvekt (EN45501)",
            Kategori: "",
            Delinger: "5.00 g"
        },
        {
            Merking: "67890",
            Fabrikat: "Fabrikat B",
            Modell: "Modell Y",
            Instrumenttype: "Ismaskin",
            Serienummer: "SN789012",
            Jvstrekkode: "JV123456",
            OpprettelsesDato: "2023-05-20",
            SisteMeldingDato: "2023-12-01",
            TypeprovingssertifikatNr: "TP789012",
            MaksKapasitet: "600kg",
            Plombenavn: "",
            Plombetype: "",
            Plombenummer: "",
            Plombeplassering: "",
            Status: "",
            Instrumentdeltype: "Butikkvekt (EN45501)",
            Kategori: "",
            Delinger: "5.00 g"
        }
    ];

    const handleCloseSnack = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnack(false);
    }

    const handleCloseSnackError = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackError(false);
    }

    const handleLastOppTypeprovingssertifikat = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setTypeprovingssertifikat(e.target.files[0]);
        }
    };

    const handleLastOppSamsvarserklaering = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setSamsvarserklaering(e.target.files[0]);
        }
    };

    const handleLastOppFiler = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setFiler(Array.from(e.target.files));
        }
    };



    

    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setValueTab(newValue);
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        const formData = {
          instrumentType,
          installationDate,
          fabrikat,
          modell,
          serienummer,
          merking,
        };
        console.log('Form Data:', formData);
      };


    function getaktor(orgnr: any) {
        // axios.get(APIURL + "" + orgnr, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
        //     .then((response: any) => {
        //         if (response.status === 200) {
        //             console.log(response.data);
        //             setAktor(response.data);
        //             setAktivAktorId(response.data[0].AktorId);
        //         }
        //         else {
        //         }
        //         setisLoading(false);
        //     })
        //     .catch((e: Error) => {
        //         setisLoading(false);
        //     });
    }

    const instrumenterGruppert = testdata.reduce((acc: Record<string, Instrument[]>, instrument) => {
        const { Instrumenttype } = instrument;
    
        if (!acc[Instrumenttype]) {
          acc[Instrumenttype] = [];
        }
    
        acc[Instrumenttype].push(instrument);
        return acc;
      }, {});
    
      const sorterteInstrumentTyper = Object.keys(instrumenterGruppert).sort();


    useEffect(() => {
        getaktor(aktorid);
        setisLoading(false);
        // eslint-disable-next-line
    }, []);


    return (
        <main>
            {
                isLoading ?
                    <div>
                        <Backdrop
                            open={isLoading}
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        >
                            <CircularProgress color="inherit" />
                            <Typography sx={{ pl: 2 }}>
                                <Text tid={"oppdaterermelding"} />
                            </Typography>
                        </Backdrop >
                    </div>
                    :
                    <div>
                        <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack} anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}>
                            <Alert onClose={handleCloseSnack} severity="success" sx={{ width: '100%' }}>
                                {snackMelding}
                            </Alert>
                        </Snackbar>
                        <Snackbar open={openSnackError} autoHideDuration={60000} onClose={handleCloseSnackError} anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}>
                            <Alert onClose={handleCloseSnackError} severity="error" sx={{ width: '100%' }}>
                                {snackMelding}
                            </Alert>
                        </Snackbar>

                        <ToppMeny />
                        <Breadcrumbs aria-label="breadcrumb" sx={{ padding: 1 }}>
                            <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="inherit"
                                href="/forsiden"
                            >
                                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                <Text tid="forsiden" />
                            </Link>
                            <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="inherit"
                                href="/taksameter"
                            >
                                Innmelding
                            </Link>
                            <Typography
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="text.primary"
                            >
                                {aktor[0]?.Navn}
                            </Typography>
                        </Breadcrumbs>

                        <Grid container spacing={2} p={2}>
                            <Grid item xs={6}>
                                <Card variant="outlined">
                                    <CardContent>
                                    <Box display="flex" alignItems="center"  sx={{ marginBottom: '8px' }}>
                                    <BusinessIcon  sx={{ marginRight: '4px', color:"text.secondary" }} /> 
                                        <Typography variant="body1" color="text.secondary">
                                        Juridisk eier av instrument(ene)
                                        </Typography>
                                    </Box>
                                        <Divider sx={{ marginBottom: '10px' }} />
                                        <Typography variant="h6" align="center" color="text.secondary">
                                            {aktor[0]?.Navn}
                                        </Typography>
                                        <Typography align="center" color="text.secondary">
                                            {aktorid}
                                        </Typography>
                                        <Grid container>
                                            <Grid item xs={4}>
                                                <Typography variant="body2" color="text.secondary" className='jvtypeicon' gutterBottom>
                                                    <HomeIcon className='jvtexticon' /> {aktor[0]?.Besoksadresse}<br />
                                                    {aktor[0]?.Besokspostnummer} {aktor[0]?.Besokssted}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography variant="body2" color="text.secondary" className='jvtypeicon' gutterBottom>
                                                    <PhoneIcon className='jvtexticon' /> {aktor[0]?.Mobilnummer}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography variant="body2" color="text.secondary" className='jvtypeicon' gutterBottom>
                                                    <EmailIcon className='jvtexticon' /> {aktor[0]?.Epost}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Box display="flex" justifyContent="flex-end">
                                            <Button variant="contained" color="primary" /* onClick={ModalEndreAktorAapne} */ size="small"><EditIcon className='jvbtnicon' /> {Text({ tid: "endre" })}</Button>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid item xs={6}>
                                <Card variant="outlined">
                                    <CardContent>
                                    <Box display="flex" alignItems="center"  sx={{ marginBottom: '8px' }}>
                                    <PlaceIcon  sx={{ marginRight: '4px', color:"text.secondary" }} /> 
                                        <Typography variant="body1" color="text.secondary">
                                        Lokasjon til instrument(ene) (underenhet)
                                        </Typography>
                                    </Box>
                                        <Divider sx={{ marginBottom: '10px' }} />
                                        <Typography variant="h6" align="center" color="text.secondary">
                                            {aktor[0]?.Navn}
                                        </Typography>
                                        <Typography align="center" color="text.secondary">
                                            {aktorid}
                                        </Typography>
                                        <Grid container>
                                            <Grid item xs={4}>
                                                <Typography variant="body2" color="text.secondary" className='jvtypeicon' gutterBottom>
                                                    <HomeIcon className='jvtexticon' /> {aktor[0]?.Besoksadresse}<br />
                                                    {aktor[0]?.Besokspostnummer} {aktor[0]?.Besokssted}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography variant="body2" color="text.secondary" className='jvtypeicon' gutterBottom>
                                                    <PhoneIcon className='jvtexticon' /> {aktor[0]?.Mobilnummer}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography variant="body2" color="text.secondary" className='jvtypeicon' gutterBottom>
                                                    <EmailIcon className='jvtexticon' /> {aktor[0]?.Epost}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Box display="flex" justifyContent="flex-end">
                                            <Button variant="contained" color="primary" /* onClick={ModalEndreAktorAapne} */ size="small"><EditIcon className='jvbtnicon' /> {Text({ tid: "endre" })}</Button>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
    
                            <Grid item xs={12} md={12}>
                                <Tabs value={valueTab} onChange={handleChangeTab} aria-label="Tabs" sx={{ mb: 2 }}>
                                    <Tab icon={<CheckIcon color="success" />} label={"Aktive instrumenter (" + aktor[0]?.instrumenter + ")"} iconPosition="start" />
                                    <Tab icon={<DeleteIcon color="error" />} label={"Kasserte instrumenter (" + aktor[0]?.instrumenterKassert + ")"} iconPosition="start" />
                                    <Tab icon={<AddIcon color="info" />} label={"Legg til nytt instrument"} iconPosition="start" />
                                </Tabs>

                                {/* Aktive instrumenter tab */}
                                <TabPanel value={valueTab} index={0}>   
                                    {sorterteInstrumentTyper.map((instrumenttype) => (
                                        <div key={instrumenttype}>
                                            <Typography variant="h5" gutterBottom>{instrumenttype}</Typography>
                                            {instrumenterGruppert[instrumenttype].map((instrument) => (
                                            <Card key={instrument.Serienummer} sx={{ mb: 2 }}>
                                                <CardContent>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={12}>
                                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                                    <Box display="flex" alignItems="center">
                                                        <Typography variant="h5" color="text.primary" sx={{ mr: 2 }}>
                                                        <b>{instrument.Merking} - {instrument.Fabrikat}, {instrument.Modell}</b>
                                                        </Typography>
                                                        <Typography variant="h6" color="text.secondary">
                                                        ({instrument.Instrumenttype}({instrument.TypeprovingssertifikatNr}))
                                                        </Typography>
                                                        </Box>
                                                        <Box display="flex" gap={1} justifyContent="flex-end">
                                                        <Button 
                                                            variant="contained" 
                                                            color="primary" 
                                                            size="small" 
                                                            sx={{ whiteSpace: "nowrap", display: "inline-flex", alignItems: "center", minWidth: "auto" }}
                                                        >
                                                            <EditIcon className='jvbtnicon' /> Endre
                                                        </Button>
                                                        <Button 
                                                            variant="contained" 
                                                            color="error" 
                                                            size="small" 
                                                            sx={{ whiteSpace: "nowrap", display: "inline-flex", alignItems: "center", minWidth: "auto", marginLeft: 1 }}
                                                        >
                                                            <DeleteForeverIcon className='jvbtnicon' /> Kasser instrument
                                                        </Button>
                                                        </Box>
                                                    </Box>

                                                    <Box display="inline-flex" alignItems="center" sx={{ color: '#2a3eb1' }}>
                                                        <CalendarMonthIcon sx={{ fontSize: 16 }}/>
                                                        <Typography variant="body1" color="text.secondary" sx={{ mr: 1 }}>
                                                            {instrument.OpprettelsesDato ? moment(instrument.OpprettelsesDato).format('DD.MM.YYYY') : (
                                                                <Typography variant="body2" color="warning">
                                                                    (Opprettelsesdato mangler)
                                                                </Typography>
                                                            )}    
                                                        </Typography>
                                                        <FingerprintIcon sx={{ fontSize: 16 }}/> 
                                                        <Typography variant="body1" color="text.secondary" sx={{ mr: 1 }}>
                                                            {instrument.Serienummer ? instrument.Serienummer : (
                                                            <Typography variant="body2" color="warning">
                                                                (Serienummer mangler)
                                                            </Typography>
                                                            )}  
                                                        </Typography>
                                                        <QrCodeIcon sx={{ fontSize: 16 }}/>
                                                        <Typography variant="body1" color="text.secondary">
                                                            {instrument.Jvstrekkode ? instrument.Jvstrekkode : (
                                                            <Typography variant="body2" color="warning">
                                                                (JV strekkode mangler)
                                                            </Typography>
                                                            )}  
                                                        </Typography>
                                                    </Box>

                                                    </Grid>
                                                    <Grid item xs={12}>
                                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                                        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                                                        Instrumentdeler
                                                        </Typography>
                                                        <Box display="flex" gap={1} justifyContent="flex-end">
                                                        <Button 
                                                            variant="contained" 
                                                            color="primary" 
                                                            size="small" 
                                                            sx={{ whiteSpace: "nowrap", display: "inline-flex", alignItems: "center", minWidth: "auto" }}
                                                        >
                                                            +
                                                        </Button>
                                                        </Box>
                                                        </Box>

                                                        <TableContainer component={Paper}>
                                                        <Table>
                                                            <TableHead sx={{ backgroundColor: '#2196f3' }}>
                                                            <TableRow>
                                                                <TableCell sx={{ color: 'white'}}>Status</TableCell>
                                                                <TableCell sx={{ color: 'white'}}>Merking</TableCell>
                                                                <TableCell sx={{ color: 'white'}}>Serienummer</TableCell>
                                                                <TableCell sx={{ color: 'white'}}>Instrumenttype</TableCell>
                                                                <TableCell sx={{ color: 'white'}}>Instrumentdeltype</TableCell>
                                                                <TableCell sx={{ color: 'white'}}>Kategori/Klasse</TableCell>
                                                                <TableCell sx={{ color: 'white'}}>Kapasitet</TableCell>
                                                                <TableCell sx={{ color: 'white'}}>Delinger</TableCell>
                                                                <TableCell></TableCell>
                                                            </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                <TableRow key={instrument.Serienummer}>
                                                                <TableCell>{instrument.Status}</TableCell> 
                                                                <TableCell>{instrument.Merking}</TableCell>
                                                                <TableCell>{instrument.Serienummer}</TableCell>
                                                                <TableCell>{instrument.Instrumenttype}</TableCell>
                                                                <TableCell>{instrument.Instrumentdeltype}</TableCell>
                                                                <TableCell>{instrument.Kategori}</TableCell>
                                                                <TableCell>{instrument.MaksKapasitet}</TableCell>
                                                                <TableCell>{instrument.Delinger}</TableCell> 
                                                                <TableCell align="right">
                                                                    <Button variant="contained" color="primary" size="small" sx={{ mr:1, minWidth: '32px' }}>
                                                                        <EditIcon />
                                                                    </Button>
                                                                    <Button variant="contained" color="error" size="small" sx={{  minWidth: '32px' }}>
                                                                        <DeleteForeverIcon />
                                                                    </Button>
                                                                </TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                        </TableContainer>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                                        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                                                        Plomber
                                                        </Typography>
                                                        <Box display="flex" gap={1} justifyContent="flex-end">
                                                        <Button 
                                                            variant="contained" 
                                                            color="primary" 
                                                            size="small" 
                                                            sx={{ whiteSpace: "nowrap", display: "inline-flex", alignItems: "center", minWidth: "auto" }}
                                                        >
                                                            +
                                                        </Button>
                                                        </Box>
                                                        </Box>

                                                        <TableContainer component={Paper}>
                                                        <Table>
                                                            <TableHead sx={{ backgroundColor: '#2196f3' }}>
                                                            <TableRow>
                                                                <TableCell sx={{ color: 'white'}}>Navn</TableCell>
                                                                <TableCell sx={{ color: 'white'}}>Type</TableCell>
                                                                <TableCell sx={{ color: 'white'}}>Nummer</TableCell>
                                                                <TableCell sx={{ color: 'white'}}>Plassering</TableCell>
                                                            </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {instrument.Plombenavn || instrument.Plombetype || instrument.Plombenummer || instrument.Plombeplassering ? (
                                                                <TableRow key={instrument.Serienummer}>
                                                                <TableCell>{instrument.Plombenavn}</TableCell>
                                                                <TableCell>{instrument.Plombetype}</TableCell>
                                                                <TableCell>{instrument.Plombenummer}</TableCell>
                                                                <TableCell>{instrument.Plombeplassering}</TableCell>
                                                                </TableRow>
                                                                ) : (
                                                                    <TableRow>
                                                                        <TableCell colSpan={4} align="left">
                                                                            <Box sx={{
                                                                                backgroundColor: '#FFF8E1',
                                                                                border: '1px solid #FFECB3',
                                                                                borderRadius: '4px',
                                                                                padding: '8px',
                                                                                flexDirection: 'column',
                                                                            }}
                                                                            >
                                                                                <Box display="inline-flex" alignItems="center" sx={{ color: 'text.secondary' }}>
                                                                                <InfoIcon />
                                                                                    <Typography variant="body1" color="text.secondary"  sx={{ ml: 1 }}>
                                                                                        Vi har ingen registrerte plomber på dette instrumentet.
                                                                                    </Typography>
                                                                                </Box>
                                                                                <Box>
                                                                                <Button variant="contained" color="warning" size='small' sx={{ mt: 1 }}>
                                                                                        Meld plombebrudd
                                                                                    </Button>
                                                                                    </Box>
                                                                            </Box>
                                                                        </TableCell>
                                                                    </TableRow>
                                                        )}
                                                            </TableBody>
                                                        
                                                        </Table>
                                                        </TableContainer>
                                                    </Grid>
                                                </Grid>
                                                </CardContent>
                                            </Card>
                                            ))}
                                        </div>
                                    ))}
                                </TabPanel>

                                {/* Kasserte instrumenter tab */}
                                <TabPanel value={valueTab} index={1}>   
                                    {sorterteInstrumentTyper.map((instrumenttype) => (
                                        <div key={instrumenttype}>
                                            <Typography variant="h5" gutterBottom>{instrumenttype}</Typography>
                                            {instrumenterGruppert[instrumenttype].map((instrument) => (
                                            <Card key={instrument.Serienummer} sx={{ mb: 2 }}>
                                                <CardContent>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={12}>
                                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                                    <Box display="flex" alignItems="center">
                                                        <Typography variant="h5" color="text.primary" sx={{ mr: 2 }}>
                                                        <b>{instrument.Merking} - {instrument.Fabrikat}, {instrument.Modell}</b>
                                                        </Typography>
                                                        <Typography variant="h6" color="text.secondary">
                                                        ({instrument.Instrumenttype}({instrument.TypeprovingssertifikatNr}))
                                                        </Typography>
                                                        </Box>
                                                        <Box display="flex" gap={1} justifyContent="flex-end">
                                                        <Button 
                                                            variant="contained" 
                                                            color="success"
                                                            size="small" 
                                                            sx={{ whiteSpace: "nowrap", display: "inline-flex", alignItems: "center", minWidth: "auto", marginLeft: 1 }}
                                                        >
                                                            <AutorenewIcon className='jvbtnicon' /> Reaktiver instrument
                                                        </Button>
                                                        </Box>
                                                    </Box>

                                                    <Box display="inline-flex" alignItems="center" sx={{ color: '#2a3eb1' }}>
                                                        <CalendarMonthIcon sx={{ fontSize: 16 }}/>
                                                        <Typography variant="body1" color="text.secondary" sx={{ mr: 1 }}>
                                                            {instrument.OpprettelsesDato ? moment(instrument.OpprettelsesDato).format('DD.MM.YYYY') : (
                                                                <Typography variant="body2" color="warning">
                                                                    (Opprettelsesdato mangler)
                                                                </Typography>
                                                            )}    
                                                        </Typography>
                                                        <FingerprintIcon sx={{ fontSize: 16 }}/> 
                                                        <Typography variant="body1" color="text.secondary" sx={{ mr: 1 }}>
                                                            {instrument.Serienummer ? instrument.Serienummer : (
                                                            <Typography variant="body2" color="warning">
                                                                (Serienummer mangler)
                                                            </Typography>
                                                            )}  
                                                        </Typography>
                                                        <QrCodeIcon sx={{ fontSize: 16 }}/>
                                                        <Typography variant="body1" color="text.secondary">
                                                            {instrument.Jvstrekkode ? instrument.Jvstrekkode : (
                                                            <Typography variant="body2" color="warning">
                                                                (JV strekkode mangler)
                                                            </Typography>
                                                            )}  
                                                        </Typography>
                                                    </Box>

                                                    </Grid>
                                                    <Grid item xs={12}>
                                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                                        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                                                        Instrumentdeler
                                                        </Typography>
                                                        </Box>

                                                        <TableContainer component={Paper}>
                                                        <Table>
                                                            <TableHead sx={{ backgroundColor: '#2196f3' }}>
                                                            <TableRow>
                                                                <TableCell sx={{ color: 'white'}}>Status</TableCell>
                                                                <TableCell sx={{ color: 'white'}}>Merking</TableCell>
                                                                <TableCell sx={{ color: 'white'}}>Serienummer</TableCell>
                                                                <TableCell sx={{ color: 'white'}}>Instrumenttype</TableCell>
                                                                <TableCell sx={{ color: 'white'}}>Instrumentdeltype</TableCell>
                                                                <TableCell sx={{ color: 'white'}}>Kategori/Klasse</TableCell>
                                                                <TableCell sx={{ color: 'white'}}>Kapasitet</TableCell>
                                                                <TableCell sx={{ color: 'white'}}>Delinger</TableCell>
                                                            </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                <TableRow key={instrument.Serienummer}>
                                                                <TableCell>{instrument.Status}</TableCell> 
                                                                <TableCell>{instrument.Merking}</TableCell>
                                                                <TableCell>{instrument.Serienummer}</TableCell>
                                                                <TableCell>{instrument.Instrumenttype}</TableCell>
                                                                <TableCell>{instrument.Instrumentdeltype}</TableCell>
                                                                <TableCell>{instrument.Kategori}</TableCell>
                                                                <TableCell>{instrument.MaksKapasitet}</TableCell>
                                                                <TableCell>{instrument.Delinger}</TableCell> 
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                        </TableContainer>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                                        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                                                        Plomber
                                                        </Typography>
                                                        </Box>

                                                        <TableContainer component={Paper}>
                                                        <Table>
                                                            <TableHead sx={{ backgroundColor: '#2196f3' }}>
                                                            <TableRow>
                                                                <TableCell sx={{ color: 'white'}}>Navn</TableCell>
                                                                <TableCell sx={{ color: 'white'}}>Type</TableCell>
                                                                <TableCell sx={{ color: 'white'}}>Nummer</TableCell>
                                                                <TableCell sx={{ color: 'white'}}>Plassering</TableCell>
                                                            </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {instrument.Plombenavn || instrument.Plombetype || instrument.Plombenummer || instrument.Plombeplassering ? (
                                                                <TableRow key={instrument.Serienummer}>
                                                                <TableCell>{instrument.Plombenavn}</TableCell>
                                                                <TableCell>{instrument.Plombetype}</TableCell>
                                                                <TableCell>{instrument.Plombenummer}</TableCell>
                                                                <TableCell>{instrument.Plombeplassering}</TableCell>
                                                                </TableRow>
                                                                ) : (
                                                                    <TableRow>
                                                                        <TableCell colSpan={4} align="left">
                                                                            <Box sx={{
                                                                                backgroundColor: '#FFF8E1',
                                                                                border: '1px solid #FFECB3',
                                                                                borderRadius: '4px',
                                                                                padding: '8px',
                                                                                flexDirection: 'column',
                                                                            }}
                                                                            >
                                                                                <Box display="inline-flex" alignItems="center" sx={{ color: 'text.secondary' }}>
                                                                                <InfoIcon />
                                                                                    <Typography variant="body1" color="text.secondary"  sx={{ ml: 1 }}>
                                                                                        Vi har ingen registrerte plomber på dette instrumentet.
                                                                                    </Typography>
                                                                                </Box>
                                                                            </Box>
                                                                        </TableCell>
                                                                    </TableRow>
                                                        )}
                                                            </TableBody>
                                                        
                                                        </Table>
                                                        </TableContainer>
                                                    </Grid>
                                                </Grid>
                                                </CardContent>
                                            </Card>
                                            ))}
                                        </div>
                                        ))}
                                </TabPanel>
                                
                                {/* Legg til nytt instrument tab */}
                                <TabPanel value={valueTab} index={2}>  
                                    <Box display="flex" alignItems="center">
                                            <InfoIcon sx={{ mr: 1, mb: 2 }}/>
                                            <Typography variant="h6" sx={{ mb: 2 }}>
                                                Legg til nytt ferdigsamsvarsvurdert måleredskap
                                            </Typography>
                                    </Box>
                                    <Box sx={{
                                        backgroundColor: '#c4d7f5',
                                        borderRadius: '4px',
                                        padding: '20px',
                                        flexDirection: 'column',
                                        }}
                                    >
                                    <Box display="inline-flex" alignItems="center" sx={{ color: '#114391', position: 'relative' }}>
                                        <Typography variant="body1" color="#114391"  >
                                            <Box component="span" sx={{ position: 'absolute', left: '0', top: '0' }}>
                                                <InfoIcon />
                                            </Box>
                                                &nbsp;&nbsp;&nbsp;&nbsp;;&nbsp; Hvis det er satt inn komponenter som ikke er omfattet/beskrevet i typeprøvingssertifikat eller
                                                enhetsverifikasjonssertifikat må det søkes om førstegangsverifikasjon. 
                                                Hvis du ikke er produsent eller autorisert representant for produsenten av måleinstrument/målesystemet må du
                                                kontakte din leverandør av målesystemet for å avklare hvem som skal bestille førstegangsverifikasjon.
                                        </Typography>
                                    </Box>
                                    </Box>

                                    <Card sx={{ mb: 2, mt: 2 }}>
                                        <CardContent>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                                    <Typography variant="h6" color="text.primary">
                                                        <b>Instrumentinformasjon</b>
                                                    </Typography>
                                                    </Box>
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <FormControl fullWidth>
                                                    <InputLabel id="instrument-type-label">Instrumenttype</InputLabel>
                                                    <Select
                                                        labelId="instrument-type-label"
                                                        value={instrumentType}
                                                        onChange={(e) => setInstrumentType(e.target.value)}
                                                        label="Instrumenttype"
                                                    >
                                                        <MenuItem value="type1">Type 1</MenuItem>
                                                        <MenuItem value="type2">Type 2</MenuItem>
                                                        <MenuItem value="type3">Type 3</MenuItem>
                                                    </Select>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <TextField
                                                        fullWidth
                                                        label="Installasjonsdato"
                                                        type="date"
                                                        value={installationDate}
                                                        onChange={(e) => setInstallationDate(e.target.value)}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </Grid>

                                            
                                                <Grid item xs={4}>
                                                    <FormControl fullWidth>
                                                    <InputLabel id="fabrikat-label">Fabrikat</InputLabel>
                                                    <Select
                                                        labelId="fabrikat-label"
                                                        value={fabrikat}
                                                        onChange={(e) => setFabrikat(e.target.value)}
                                                        label="Fabrikat"
                                                    >
                                                        <MenuItem value="fabrikat1">Fabrikat A</MenuItem>
                                                        <MenuItem value="fabrikat2">Fabrikat B</MenuItem>
                                                        <MenuItem value="fabrikat3">Fabrikat C</MenuItem>
                                                    </Select>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <TextField
                                                    fullWidth
                                                    label="Modell"
                                                    value={modell}
                                                    onChange={(e) => setModell(e.target.value)}
                                                    />
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <TextField
                                                    fullWidth
                                                    label="Serienummer"
                                                    value={serienummer}
                                                    onChange={(e) => setSerienummer(e.target.value)}
                                                    />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <TextField
                                                    fullWidth
                                                    label="Merking"
                                                    value={merking}
                                                    onChange={(e) => setMerking(e.target.value)}
                                                    />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                                    <Typography variant="h6" color="text.primary">
                                                        <b>Instrumentdel #1 (Du kan legge til flere etter opprettelse av instrumentet)</b>
                                                    </Typography>
                                                    </Box>
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <TextField
                                                    fullWidth
                                                    label="Maks kapasitet"
                                                    value={maksKapasitet}
                                                    onChange={(e) => setModell(e.target.value)}
                                                    />
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <FormControl fullWidth>
                                                    <InputLabel id="maksKapasitetBenevnelse-label">Maks kapasitet Benevnelse</InputLabel>
                                                    <Select
                                                        labelId="maksKapasitetBenevnelse-label"
                                                        value={maksKapasitetBenevnelse}
                                                        onChange={(e) => setMaksKapasitetBenevnelse(e.target.value)}
                                                        label="Maks kapasitet benevnelse"
                                                    >
                                                        <MenuItem value="benevnelse1">Benevnelse A</MenuItem>
                                                        <MenuItem value="benevnelse2">Benevnelse B</MenuItem>
                                                        <MenuItem value="benevnelse3">Benevnelse C</MenuItem>
                                                    </Select>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <TextField
                                                    fullWidth
                                                    label="Delinger"
                                                    value={delinger}
                                                    onChange={(e) => setDelinger(e.target.value)}
                                                    />
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <FormControl fullWidth>
                                                    <InputLabel id="delingerMaaleenhet-label">Delinger måleenhet</InputLabel>
                                                    <Select
                                                        labelId="delingerMaaleenhet-label"
                                                        value={delingerMaaleenhet}
                                                        onChange={(e) => setDelingerMaaleenhet(e.target.value)}
                                                        label="Delinger Maaleenhet"
                                                    >
                                                        <MenuItem value="maaleenhet1">Måleenhet A</MenuItem>
                                                        <MenuItem value="maaleenhet2">Måleenhet B</MenuItem>
                                                        <MenuItem value="maaleenhet3">Måleenhet C</MenuItem>
                                                    </Select>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <FormControl fullWidth>
                                                    <InputLabel id="instrumentdelType-label">Instrumentdel Type</InputLabel>
                                                    <Select
                                                        labelId="instrumentdelType-label"
                                                        value={instrumentdelType}
                                                        onChange={(e) => setInstrumentdelType(e.target.value)}
                                                        label="InstrumentdelType"
                                                    >
                                                        <MenuItem value="type1">Type A</MenuItem>
                                                        <MenuItem value="type2">Type B</MenuItem>
                                                        <MenuItem value="type3">Type C</MenuItem>
                                                    </Select>
                                                    </FormControl>
                                                </Grid>

                                                
                                                <Grid item xs={6}>
                                                    <FormControl fullWidth>
                                                    <InputLabel id="kategori-label">Kategori (valgfritt)</InputLabel>
                                                    <Select
                                                        labelId="kategori-label"
                                                        value={kategori}
                                                        onChange={(e) => setKategori(e.target.value)}
                                                        label="Kategori"
                                                    >
                                                        <MenuItem value="kategori1">Kategori A</MenuItem>
                                                        <MenuItem value="kategori2">Kategori B</MenuItem>
                                                        <MenuItem value="kategori3">Kategori C</MenuItem>
                                                    </Select>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                                    <Typography variant="h6" color="text.primary">
                                                        Plombering
                                                    </Typography>
                                                    </Box>
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <FormControl fullWidth>
                                                    <InputLabel id="plombenavn">Plombenavn</InputLabel>
                                                    <Select
                                                        labelId="plombenavn-label"
                                                        value={plombenavn}
                                                        onChange={(e) => setPlombenavn(e.target.value)}
                                                        label="Plombenavn"
                                                    >
                                                        <MenuItem value="plombenavn1">Plombenavn 1</MenuItem>
                                                        <MenuItem value="plombenavn2">Plombenavn 2</MenuItem>
                                                        <MenuItem value="plombenavn3">Plombenavn 3</MenuItem>
                                                    </Select>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <FormControl fullWidth>
                                                    <InputLabel id="plombetype">Plombetype</InputLabel>
                                                    <Select
                                                        labelId="plombetype-label"
                                                        value={plombetype}
                                                        onChange={(e) => setPlombetype(e.target.value)}
                                                        label="Plombetype"
                                                    >
                                                        <MenuItem value="plombetype1">Plombetype 1</MenuItem>
                                                        <MenuItem value="plombetype2">Plombetype 2</MenuItem>
                                                        <MenuItem value="plombetype3">Plombetype 3</MenuItem>
                                                    </Select>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <TextField
                                                    fullWidth
                                                    label="Plombenummer (plombeidentifikasjon)"
                                                    value={plombenummer}
                                                    onChange={(e) => setPlombenummer(e.target.value)}
                                                    />
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <TextField
                                                    fullWidth
                                                    label="Plassering"
                                                    value={plassering}
                                                    onChange={(e) => setPlassering(e.target.value)}
                                                    />
                                                </Grid>


                                                <Grid item xs={12}>
                                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                                    <Typography variant="h6" color="text.primary">
                                                        Dokumenter
                                                    </Typography>
                                                    </Box>
                                                </Grid>

                                                
                                                <Grid item xs={12}>
                                                    <Typography variant="body1" color="text.secondary"  sx={{ mb: 1 }}>
                                                        Typeprøvingssertifikat (EC Type Examination certificate)
                                                    </Typography>
                                                    <Box display="flex" alignItems="center" gap={1}>
                                                        <input
                                                        accept="*"
                                                        id="typeprovingssertifikat-upload"
                                                        type="file"
                                                        style={{ display: 'none' }}
                                                        onChange={handleLastOppTypeprovingssertifikat}
                                                        />
                                                        <label htmlFor="typeprovingssertifikat-upload">
                                                        <Button
                                                            variant="contained"
                                                            component="span"
                                                            color="inherit"
                                                            sx={{ whiteSpace: 'nowrap', height: 37 }} 
                                                        >
                                                            Velg fil
                                                        </Button>
                                                        </label>
                                                        <TextField
                                                        variant="outlined"
                                                        value={typeprovingssertifikat ? typeprovingssertifikat.name : 'Ingen fil er valgt'}
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        sx={{ height: 37, '& .MuiInputBase-root': { height: '100%' } }}
                                                        fullWidth
                                                        />
                                                    </Box>
                                                </Grid>
                                                
                                                <Grid item xs={12}>
                                                    <Typography variant="body1" color="text.secondary"  sx={{ mb: 1 }}>
                                                        Samsvarserklæring
                                                    </Typography>
                                                    <Box display="flex" alignItems="center" gap={1}>
                                                        <input
                                                        accept="*"
                                                        id="samsvarserklaering-upload"
                                                        type="file"
                                                        style={{ display: 'none' }}
                                                        onChange={handleLastOppSamsvarserklaering}
                                                        />
                                                        <label htmlFor="samsvarserklaering-upload">
                                                        <Button
                                                            variant="contained"
                                                            component="span"
                                                            color="inherit"
                                                            sx={{ whiteSpace: 'nowrap', height: 37 }} 
                                                        >
                                                            Velg fil
                                                        </Button>
                                                        </label>
                                                        <TextField
                                                        variant="outlined"
                                                        value={samsvarserlaering ? samsvarserlaering.name : 'Ingen fil er valgt'}
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        sx={{ height: 37, '& .MuiInputBase-root': { height: '100%' } }}
                                                        fullWidth
                                                        />
                                                    </Box>
                                                </Grid>

                                                <Grid item xs={12}>
                                                <Typography variant="body1" color="text.secondary"  sx={{ mb: 1 }}>
                                                        Filer (ekstra dokumentasjon, manualer etc)
                                                    </Typography>
                                                    <Box display="flex" alignItems="center" gap={1}>
                                                    <input
                                                        accept="*"
                                                        id="filer-upload"
                                                        type="file"
                                                        multiple
                                                        style={{ display: 'none' }}
                                                        onChange={handleLastOppFiler}
                                                    />
                                                    <label htmlFor="filer-upload">
                                                        <Button 
                                                        variant="contained" 
                                                        component="span" 
                                                        color="inherit"
                                                        sx={{ whiteSpace: 'nowrap', height: 37 }}
                                                        >
                                                        Velg filer
                                                        </Button>
                                                    </label>
                                                    <TextField
                                                        variant="outlined"
                                                        value={filer.length > 0 ? `${filer.length} filer valgt` : 'Ingen filer er valgt'}
                                                        InputProps={{
                                                        readOnly: true,
                                                        }}
                                                        sx={{ height: 37, '& .MuiInputBase-root': { height: '100%' } }}
                                                        fullWidth
                                                    />
                                                    </Box>
                                                    {/* Liste over valgte filer */}
                                                    {filer.length > 0 && (
                                                    <List>
                                                        {filer.map((file, index) => (
                                                        <ListItem key={index}>
                                                            <Typography variant="body2">{file.name}</Typography>
                                                        </ListItem>
                                                        ))}
                                                    </List>
                                                    )}
                                                </Grid>
                                                                                                    


                                                <Grid item xs={12}>
                                                    <Box display="flex" gap={2}>
                                                        <Button
                                                            variant="contained"
                                                            color="success"
                                                            sx={{
                                                                minWidth: 'auto',
                                                                whiteSpace: 'nowrap',
                                                                padding: '6px 12px',
                                                            }}
                                                            onClick={handleSubmit}
                                                        >
                                                            Legg til instrument
                                                        </Button>

                                                        <Button
                                                            variant="contained"
                                                            color="error"
                                                            sx={{
                                                                minWidth: 'auto',
                                                                whiteSpace: 'nowrap',
                                                                padding: '6px 12px',
                                                            }}
                                                            onClick={handleSubmit}
                                                        >
                                                            Nullstill
                                                        </Button>
                                                    </Box>
                                                </Grid>

                                            </Grid>
                                        </CardContent>
                                        </Card>


                                </TabPanel>
                            </Grid>
                        </Grid>
                    </div>
            }
        </main >
    )
}

export default InnsendingDetaljer;